import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
  GET_PRODUCT_LIST_DATA: createAsyncTypes("GET_PRODUCT_LIST_DATA"),
  GET_PRODUCT_DETAILS_DATA: createAsyncTypes("GET_PRODUCT_DETAILS_DATA"),
  SET_PRODUCT_STATE: "SET_PRODUCT_STATE",
};

export const get_product_list_data = createAsyncActions("GET_PRODUCT_LIST_DATA");
export const get_product_details_data = createAsyncActions("GET_PRODUCT_DETAILS_DATA");
export const set_product_state = val => ({
  type: types.SET_PRODUCT_STATE,
  state: val
});

const initialState = {
    product_list_data: null,
    product_detail: null,
    loading: null,
    response: null,
    error: null
};

const product = (state = initialState, action) => {
    switch (action.type) {  
      case types.SET_PRODUCT_STATE:
        return {
            ...state,
            ...action.state
        }; 
      case types.GET_PRODUCT_LIST_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_PRODUCT_LIST_DATA.SUCCESS:
        return {
          ...state,
          product_list_data: action.payload.product_list_data,
          loading: null
        };
      case types.GET_PRODUCT_LIST_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
      };
      case types.GET_PRODUCT_DETAILS_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_PRODUCT_DETAILS_DATA.SUCCESS:
        return {
          ...state,
          product_detail: action.payload.product_detail,
          loading: null
        };
      case types.GET_PRODUCT_DETAILS_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
      };
      default:
          return state;
      }
};

export default product;