import { combineReducers } from 'redux'
import settingReducer from './reducers/setting'
import bakeryReducer from './reducers/bakery'
import productReducer from './reducers/product'
import authReducer from './reducers/auth'
import orderReducer from './reducers/order'
import customerReducer from './reducers/customer'

const rootReducer = combineReducers({
  setting: settingReducer,
  bakery: bakeryReducer,
  product: productReducer,
  auth : authReducer,
  order : orderReducer,
  customer : customerReducer
})

export default rootReducer