import React, { Component } from 'react';
import { withNamespaces ,Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner3  from "../Banner/Banner3"
import LoginForm from "./LoginForm/LoginForm";
import Modal from 'react-modal';
import PasswordRegen from './PasswordRegen/PasswordRegen';

const customStyles = {
    content : {
      top                   : '15%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, 0%)'
    }
  };

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    openModal(modal) {
        this.setState({[modal]: true});
    }
    
    closeModal(modal) {
        this.setState({[modal]: false});
    }
    render() { 
        const { t } = this.props;
        return ( 
            <>
            <Header />
            <Banner3 />
            <div className="product_detail_cart_area">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-7">
                            <div className="shadow_box log_box">
                                <div className="card-header">{t("login")}</div>
                                <div className="card-body">
                                    <div className="row">
                                        
                                    </div>
                                    <LoginForm openModal={(val) => {
                                    this.openModal(val);
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal
            isOpen={this.state.forgotPasswordModal}
            onRequestClose={() => this.closeModal('forgotPasswordModal')}
            style={customStyles}
            contentLabel="Example Modal"
            ><div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle"><i className="fa fa-user-o" aria-hidden="true"></i> {t("password_regeneration")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={() => this.closeModal('forgotPasswordModal')}>
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <PasswordRegen onClose={() => {
                    this.closeModal('forgotPasswordModal');
                    }} />
            </div></Modal>
            </>
         );
    }
}
 
export default withNamespaces()(Login);