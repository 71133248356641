import api from "api";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    add_to_cart, cart_list, delete_cart_product, authorize_cart_data , checkout, order_list
} from "../reducers/order";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* postAddToCartData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  let authorization = ''
  let loggedDetails = yield select(state => state.auth.login_details);
  
  if(loggedDetails){
    authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
  }
  try {
    const res = yield api.post("/addToCart",payload, {
      headers: { "x-lang": lang , "x-access-token" : authorization }
    });

    yield put(add_to_cart.success({ add_to_cart: res.data }));
  } catch (err) {
    yield put(add_to_cart.failure({ error: formatError(err) }));
  }
}

function* watchPostAddToCartData(){
  yield takeLatest(types.ADD_TO_CART.REQUEST, postAddToCartData);
}

function* postCartListData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  // let authorization = ''
  // let loggedDetails = yield select(state => state.auth.login_details);
  
  // if(loggedDetails){
  //   authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
  // }
  try {
    const res = yield api.post("/cartList",{session_id:payload.session_id}, {
      headers: { "x-lang": lang , "x-access-token" : payload.authorization }
    });

    yield put(cart_list.success({ cart_list_data: res.data }));
  } catch (err) {
    yield put(cart_list.failure({ error: formatError(err) }));
  }
}

function* watchPostCartListData(){
  yield takeLatest(types.CART_LIST.REQUEST, postCartListData);
}

function* postDeleteCartProductData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  
  try {
    const res = yield api.post("/deleteCartData",payload, {
      headers: { "x-lang": lang }
    });

    yield put(delete_cart_product.success({ delete_cart_product_response: res.data }));
  } catch (err) {
    yield put(delete_cart_product.failure({ error: formatError(err) }));
  }
}

function* watchPostDeleteCartProductData(){
  yield takeLatest(types.DELETE_CART_PRODUCT.REQUEST, postDeleteCartProductData);
}

function* authorizeCartData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  let authorization = ''
  let loggedDetails = yield select(state => state.auth.login_details);
  
  if(loggedDetails){
    authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
  }
  try {
    const res = yield api.post("/placeSessionCartToCustomer",payload, {
      headers: { "x-lang": lang , "x-access-token" : authorization }
    });

    yield put(authorize_cart_data.success({ authorize_cart_response: res.data }));
  } catch (err) {
    yield put(authorize_cart_data.failure({ error: formatError(err) }));
  }
}

function* watchAuthorizeCartData(){
  yield takeLatest(types.AUTHORIZE_CART_DATA.REQUEST, authorizeCartData);
}

function* postCheckout({payload}){
  const lang = localStorage.getItem("i18nextLng");
  let authorization = ''
  let loggedDetails = yield select(state => state.auth.login_details);
  
  if(loggedDetails){
    authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
  }
  try {
    const res = yield api.post("/checkout",payload, {
      headers: { "x-lang": lang , "x-access-token" : authorization }
    });

    yield put(checkout.success({ checkout_response: res.data }));
  } catch (err) {
    yield put(checkout.failure({ error: formatError(err) }));
  }
}

function* watchPostCheckout(){
  yield takeLatest(types.CHECKOUT.REQUEST, postCheckout);
}

function* postOrderListData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  // let authorization = ''
  // let loggedDetails = yield select(state => state.auth.login_details);
  
  // if(loggedDetails){
  //   authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
  // }
  try {
    const res = yield api.post("/listOrders",{session_id:payload.session_id}, {
      headers: { "x-lang": lang , "x-access-token" : payload.authorization }
    });

    yield put(order_list.success({ order_list_response: res.data }));
  } catch (err) {
    yield put(order_list.failure({ error: formatError(err) }));
  }
}

function* watchPostOrderListData(){
  yield takeLatest(types.ORDER_LIST.REQUEST, postOrderListData);
}

export default function* (){
    yield all([
        watchPostAddToCartData(),
        watchPostCartListData(),
        watchPostDeleteCartProductData(),
        watchAuthorizeCartData(),
        watchPostCheckout(),
        watchPostOrderListData()
    ]);
};