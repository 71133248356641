export const createAsyncTypes = name => {
    return {
      REQUEST: `${name}_REQUEST`,
      SUCCESS: `${name}_SUCCESS`,
      FAILURE: `${name}_FAILURE`
    };
  };
  
  export const createAsyncActions = name => {
    return {
      request: (payload = {}) => ({ type: `${name}_REQUEST`, payload: { ...payload } }),
      success: (response = {}) => ({ type: `${name}_SUCCESS`, payload: { ...response } }),
      failure: (message = {}) => ({ type: `${name}_FAILURE`, payload: { ...message } })
    };
  };
  