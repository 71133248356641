import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { Link , withRouter } from 'react-router-dom'
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner  from "../Banner/Banner"
import {
    get_search_bakery_list_data
} from "../../redux/reducers/bakery";

class BakeryList extends Component {
    constructor(props){
        super(props);
        this.state = { 
            page : 0,
            search : props.match.params.search ? props.match.params.search : ''
        }
        this.pageListing = this.pageListing.bind(this)
        this.changePage = this.changePage.bind(this)
    }

    changePage(page){
        if(page == 'left'){
            let pg = this.state.page - 1;
            this.setState({
                page : pg
            },()=>{
                this.props.getBakerySearchData({search: this.state.search,page:this.state.page})  
            })
        }else if(page == 'right'){
            let pg = this.state.page + 1;
            this.setState({
                page : pg
            },()=>{
                this.props.getBakerySearchData({search: this.state.search,page:this.state.page})  
            })
        }else{
            this.setState({
                page : page
            })
            this.props.getBakerySearchData({search: this.state.search,page:page})
        }
        window.scrollTo(0, 0)
    }

    pageListing(total,limit){
        let offset = Math.ceil(parseInt(total)/parseInt(limit))
        let html = ''
        if(offset && offset>1){
            html = []
            if(this.state.page != 0){
                html.push(<li key="left" className="page-item pointer" onClick={() => this.changePage('left')}><span className="page-link pointer"><i className="fa fa-arrow-left" aria-hidden="true"></i></span></li>)
            }else{
                html.push(<li key="left" className="page-item disabled"  >
                        <span className="page-link pointer" style={{backgroundColor: "white",
                    borderColor: "white"}}>
                            <i className="fa fa-arrow-left" style={{color: "grey"}} aria-hidden="true"></i></span></li>)
            }
            let cnt = 0;
            for ( let j= 0; j < offset; j++) {
                cnt++;
                let active = this.state.page == j ? 'active' : '';
                html.push(<li key={j} onClick={() => this.changePage(j)} className={`page-item ${active}`}>
                    <a className="page-link pointer" >{j + 1}</a></li>)
            }
            if(this.state.page != cnt-1){
                html.push(<li key="right" className="page-item pointer" onClick={() => this.changePage('right')}><a className="page-link pointer" ><i className="fa fa-arrow-right" aria-hidden="true"></i></a></li>)
            }else{
                html.push(<li key="right" className="page-item disabled" ><a className="page-link pointer" style={{backgroundColor: "white",
                borderColor: "white"}}><i className="fa fa-arrow-right" style={{color: "grey"}} aria-hidden="true"></i></a></li>)
            }
            
                                    
        }
        
        return html;
    }

    componentWillReceiveProps(){
        let url = window.location.href;
        let search = url.split('bakeryList/')[1];
        if(this.state.search != search){
            this.setState({
                search : search,
                page : 0,
            })
        }else{
            this.setState({
                // page : 0,
            })
        }
        
    }
    
    render() { 
        const { t, bakery_search_data, lang } = this.props;
        return ( 
            <>
            <Header />
            <Banner />
            <section className="popularies_wrap">
                <div className="container">
                    <div className="row">
                    {bakery_search_data && bakery_search_data.responseStatus.STATUS == "SUCCESS" && bakery_search_data.responseData.data.map((val) => (
                        <div className="col-md-12" key={val.id}>
                            <div className="popu_box horizontal">
                                <Link to={`/${lang}/bakeryProfile/${val.id}`}>
                                <div className="popu_box_imgcontent">
                                    <img src={val.banner_image} alt="" />
                                    
                                </div>
                                </Link>
                                <div className="popu_box_contentarea">
                                    <div className="content_area">
                                        <h4>{val.name}</h4>
                                        <p>{val.description}</p>
                                        <p><i className="fa fa-map-marker" aria-hidden="true"></i> {val.address}</p>
                                    </div>
                                    <Link to={`/${lang}/bakeryProfile/${val.id}`}>
                                    <div className="popu_boxlink"><span>{t("open_now")}</span></div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}


                    {bakery_search_data && bakery_search_data.responseStatus.STATUS == "FAILED" &&
                            <div className="col-md-12 no_bakery" ><h4>{t("no_bakery_found")}</h4></div>
                    }

                    </div>
                    {bakery_search_data && bakery_search_data.responseStatus.STATUS == "SUCCESS" && 
                    <div className="listing_pagination">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="pagination">
                        {this.pageListing(
                            bakery_search_data.responseData.total_bakery,
                            bakery_search_data.responseData.limit)}
                                </ul>
                            </div>
                        </div>
                    </div>}
                    



                </div>
            </section>
            <Footer />
            </>
         );
    }
}
const mapStateToProps = state => {
    return {
        bakery_search_data: state.bakery.bakery_search_data,
        loading: state.bakery.loading,
        response: state.bakery.response,
        error: state.bakery.error,
        lang: state.setting.lng,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getBakerySearchData: payload => dispatch(get_search_bakery_list_data.request(payload)),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);
 
export default enhance(BakeryList);