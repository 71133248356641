import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
  GET_CUSTOMER_DETAILS: createAsyncTypes("GET_CUSTOMER_DETAILS"),
  EDIT_PROFILE: createAsyncTypes("EDIT_PROFILE"),
  EDIT_SHIPPING_ADDRESS: createAsyncTypes("EDIT_SHIPPING_ADDRESS"),
  EDIT_BILLING_ADDRESS: createAsyncTypes("EDIT_BILLING_ADDRESS"),
  CHANGE_PASSWORD: createAsyncTypes("CHANGE_PASSWORD"),
  PASSWORD_REGEN: createAsyncTypes("PASSWORD_REGEN"),
  RESET_PASSWORD: createAsyncTypes("RESET_PASSWORD"),
  SET_CUSTOMER_STATE: "SET_CUSTOMER_STATE",
}

export const get_customer_details = createAsyncActions("GET_CUSTOMER_DETAILS");
export const edit_profile = createAsyncActions("EDIT_PROFILE");
export const edit_shipping_address = createAsyncActions("EDIT_SHIPPING_ADDRESS");
export const edit_billing_address = createAsyncActions("EDIT_BILLING_ADDRESS");
export const change_password = createAsyncActions("CHANGE_PASSWORD");
export const password_regen = createAsyncActions("PASSWORD_REGEN");
export const reset_password = createAsyncActions("RESET_PASSWORD");
export const set_customer_state = val => ({
  type: types.SET_CUSTOMER_STATE,
  state: val
});

const initialState = {
    customer_details_response: null,
    edit_profile_response: null,
    edit_shipping_address_response: null,
    edit_billing_address_response: null,
    change_password_response: null,
    password_regen_response: null,
    reset_password_response: null,
    loading: null,
    response: null,
    error: null
};

const customer = (state = initialState, action) => {
    switch (action.type) {  
      case types.SET_CUSTOMER_STATE:
        return {
            ...state,
            ...action.state
      }; 
      case types.GET_CUSTOMER_DETAILS.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_CUSTOMER_DETAILS.SUCCESS:
        return {
          ...state,
          customer_details_response: action.payload.customer_details_response,
          loading: null
        };
      case types.GET_CUSTOMER_DETAILS.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
        case types.EDIT_PROFILE.REQUEST:
          return {
            ...state,
            loading: action.type
          };
        case types.EDIT_PROFILE.SUCCESS:
          return {
            ...state,
            edit_profile_response: action.payload.edit_profile_response,
            loading: null
          };
        case types.EDIT_PROFILE.FAILURE:
          return {
            ...state,
            loading: null,
            error: action.payload.error
        };

        case types.EDIT_SHIPPING_ADDRESS.REQUEST:
          return {
            ...state,
            loading: action.type
          };
        case types.EDIT_SHIPPING_ADDRESS.SUCCESS:
          return {
            ...state,
            edit_shipping_address_response: action.payload.edit_shipping_address_response,
            loading: null
          };
        case types.EDIT_SHIPPING_ADDRESS.FAILURE:
          return {
            ...state,
            loading: null,
            error: action.payload.error
        };

        case types.EDIT_BILLING_ADDRESS.REQUEST:
          return {
            ...state,
            loading: action.type
          };
        case types.EDIT_BILLING_ADDRESS.SUCCESS:
          return {
            ...state,
            edit_billing_address_response: action.payload.edit_billing_address_response,
            loading: null
          };
        case types.EDIT_BILLING_ADDRESS.FAILURE:
          return {
            ...state,
            loading: null,
            error: action.payload.error
        };

        case types.CHANGE_PASSWORD.REQUEST:
          return {
            ...state,
            loading: action.type
          };
        case types.CHANGE_PASSWORD.SUCCESS:
          return {
            ...state,
            change_password_response: action.payload.change_password_response,
            loading: null
          };
        case types.CHANGE_PASSWORD.FAILURE:
          return {
            ...state,
            loading: null,
            error: action.payload.error
        };
        case types.PASSWORD_REGEN.REQUEST:
          return {
            ...state,
            loading: action.type
          };
        case types.PASSWORD_REGEN.SUCCESS:
          return {
            ...state,
            password_regen_response: action.payload.password_regen_response,
            loading: null
          };
        case types.PASSWORD_REGEN.FAILURE:
          return {
            ...state,
            loading: null,
            error: action.payload.error
        };
        case types.RESET_PASSWORD.REQUEST:
          return {
            ...state,
            loading: action.type
          };
        case types.RESET_PASSWORD.SUCCESS:
          return {
            ...state,
            reset_password_response: action.payload.reset_password_response,
            loading: null
          };
        case types.RESET_PASSWORD.FAILURE:
          return {
            ...state,
            loading: null,
            error: action.payload.error
        };
        default:
          return state;
      }
};

export default customer;