import { store } from 'react-notifications-component';

export function CheckForSession(){
    if(!localStorage.getItem('cart-session')){
        var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
        localStorage.setItem('cart-session', timeStampInMs);
    }
    return localStorage.getItem('cart-session');
}

export function showNotification(title,msg,type){
    store.addNotification({
        title: title,
        message: msg,
        type: type,                         // 'default', 'success', 'info', 'warning'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
        duration: 3000 
        }
    });
}

export function addLoginDetails(data = {}){
    if(!localStorage.getItem('loginDetails')){
        if(Object.entries(data).length > 0 && data.constructor === Object){
            localStorage.setItem('loginDetails', JSON.stringify(data));
            localStorage.setItem('remember_me_token', data.remember_me_token);
        }else{
            return data
        }
        
    }else{
        return JSON.parse(localStorage.getItem('loginDetails'));
    }
}

export function getRememberToken(){
    return localStorage.getItem('remember_me_token');
}

export function getSessionOrAuthorizedToken(loggedDetails){
    let ret = {
        authorization : '',
        session_id : CheckForSession()
    }
    if(loggedDetails){
        ret.authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
    }
    return ret;
}

export function removeLoginDetails(){
    localStorage.removeItem("loginDetails")
}

export function removeSessionId(){
    localStorage.removeItem("cart-session")
}

export const phoneValidator=/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

export function roundOffDecimal(nos){
    return (Math.round(nos * 100) / 100).toFixed(2);
}

export function retTwoDigit(nos){
    return nos.toString().length == 1 ? 0+nos.toString() : nos;
}

export function dateFormat(date){
    let intDate = parseInt(date);
    let d = new Date(intDate);
    let returnDate = retTwoDigit(d.getFullYear())+ '-' + retTwoDigit(d.getMonth()+1)  + "-" + retTwoDigit(d.getDate()) + " " + 
    retTwoDigit(d.getHours())+ ":" +retTwoDigit(d.getMinutes())+":"+retTwoDigit(d.getSeconds());
    return returnDate
}

export function dateFormatToFranceTimeZone(date){
    var d = date;
    let utc_date = retTwoDigit(d.getUTCFullYear())+ '-' + retTwoDigit(d.getUTCMonth()+1)  + "-" + retTwoDigit(d.getUTCDate()) + " " + 
    retTwoDigit(d.getUTCHours())+ ":" +retTwoDigit(d.getUTCMinutes())+":"+retTwoDigit(d.getUTCSeconds());
    // var utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() , 
    //   now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
    return utc_date
}

export function dateFormatChange(date){
    // let intDate = parseInt(date);
    let d = date;
    let returnDate = retTwoDigit(d.getFullYear())+ '-' + retTwoDigit(d.getMonth()+1)  + "-" + retTwoDigit(d.getDate()) + " " + 
    retTwoDigit(d.getHours())+ ":" +retTwoDigit(d.getMinutes())+":"+retTwoDigit(d.getSeconds());
    return returnDate
}