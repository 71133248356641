import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import i18n from "i18n";
import { compose } from "recompose";
import { connect } from 'react-redux'
import ReactGA from 'react-ga';
import { changeLang } from '../../redux/reducers/setting'
import {Link, withRouter} from 'react-router-dom'
import './Header.css'
import {
    cart_list, set_order_state
} from "../../redux/reducers/order";
import ResponsiveMenu from 'react-responsive-navbar';
import { getSessionOrAuthorizedToken, removeLoginDetails, showNotification } from '../../Helper/Helper'
import {
     set_auth_state, logout
} from "../../redux/reducers/auth";
import {
    page
} from "../../redux/reducers/setting";
import {
    set_customer_state
} from "../../redux/reducers/customer";
import CookieConsent from "react-cookie-consent";
import { trackingId, meta_eng, meta_fr } from '../../config/Config'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showLangList : false,
            lang : this.props.language,
            showDashboardList : false,
            stateLoggedDetails : 'loading'
         }
        this.toggeleLangDrop = this.toggeleLangDrop.bind(this);
    }
    componentDidMount(){
		ReactGA.initialize(trackingId);
		ReactGA.pageview(window.location.pathname + window.location.search);
        let url = window.location.href;
        let bakeryUrl = url.indexOf("bakery");
        let whoAreWeUrl = url.indexOf("who-are-we");
        let contactusUrl = url.indexOf("contact-us");
        if(bakeryUrl >= 0){
            this.setState({
                page : "bakery"
            })            
        }else if(whoAreWeUrl >= 0){
            this.setState({
                page : "whoAreWe"
            }) 
        }else if(contactusUrl >= 0){
            this.setState({
                page : "contactusUrl"
            }) 
        }else{
            var path = window.location.protocol + '//' + window.location.host + '/' + process.env.REACT_APP_TEST_FOLDER;
            // url = url.substring(0, url.length - 1);
            if(url == path){
                this.setState({
                    page : "home"
                }) 
            }
            
        }
        this.setMeta(this.state.lang);
    }
    langSelectedHandler(lang) {
        const { language, changeLang } = this.props;
        i18n.changeLanguage(lang);
        this.setState({
            showLangList : false
        })
        changeLang(lang);
        this.setMeta(lang);
    }
    setMeta(lang){
        if(lang == 'en'){
            var meta = meta_eng;
        }else{
            var meta = meta_fr;
        }
        // document.getElementById("meta_desp").setAttribute("content", meta);
        document.getElementById("bakery").setAttribute("lang", lang);
    }
    toggleDashboardDrop = () => {
        if(this.state.showDashboardList){
            this.setState({
                showDashboardList : false
            })
        }else{
            this.setState({
                showDashboardList : true
            })
        }
    }
    toggeleLangDrop(){
        if(this.state.showLangList){
            this.setState({
                showLangList : false
            })
        }else{
            this.setState({
                showLangList : true
            })
        }
    }
    logout = () => {
        const { responseDismiss , history, logout, customerResponseDismiss, loadingAuth, language} = this.props;
        
        logout({});
        responseDismiss();
        removeLoginDetails();
        customerResponseDismiss();
        history.push(`/`+language+`/`);
        
        
    }
    redirectLoginPage = () => {
        const { history, page,language } = this.props;
        page(null)
        history.push("/"+language+"/login")
    }

    loggedOutInactivity = () => {
        const { t } = this.props;
        this.logout();
        let error_msg = t("logged_out_due_to_inactivity");
        showNotification(t("error"),error_msg,'danger');
    }

    render() { 
        const { t, cart_list_data, loginDetails, cartList, customer_details_response,
            authorize_cart_response, checkout_response, edit_profile_response,
            edit_shipping_address_response, edit_billing_address_response, change_password_response,
            order_list_response, loadingOrder, loadingCustomer, cartResponseDismiss, language } = this.props;
        if(this.state.stateLoggedDetails != loginDetails){
            this.setState({
                stateLoggedDetails : loginDetails
            },()=>{
                let data = getSessionOrAuthorizedToken(loginDetails);
                cartList(data)
            })
            
        }
        if(cart_list_data && cart_list_data.responseData.customer_log_status == "INACTIVE"){
            // console.log("ok")
            cartResponseDismiss();
            this.loggedOutInactivity();
        }
        // if(customer_details_response && customer_details_response.responseStatus.STATUS == "FAILED" && customer_details_response.responseStatus.STATUSCODE == "201"){
        //     this.loggedOutInactivity();
        // }
        if(authorize_cart_response && authorize_cart_response.responseStatus.STATUS == "FAILED" && authorize_cart_response.responseStatus.STATUSCODE == "201"){
            // console.log("ok1")
            this.loggedOutInactivity();
        }
        if(checkout_response && checkout_response.responseStatus.STATUS == "FAILED" && checkout_response.responseStatus.STATUSCODE == "201"){
            // console.log("ok2")
            this.loggedOutInactivity();
        }
        if(edit_profile_response && edit_profile_response.responseStatus.STATUS == "FAILED" && edit_profile_response.responseStatus.STATUSCODE == "201"){
            // console.log("ok3")
            this.loggedOutInactivity();
        }
        if(edit_shipping_address_response && edit_shipping_address_response.responseStatus.STATUS == "FAILED" && edit_shipping_address_response.responseStatus.STATUSCODE == "201"){
            // console.log("ok4")
            this.loggedOutInactivity();
        }
        if(edit_billing_address_response && edit_billing_address_response.responseStatus.STATUS == "FAILED" && edit_billing_address_response.responseStatus.STATUSCODE == "201"){
            // console.log("ok5")
            this.loggedOutInactivity();
        }
        if(change_password_response && change_password_response.responseStatus.STATUS == "FAILED" && change_password_response.responseStatus.STATUSCODE == "201"){
            // console.log("ok6")
            this.loggedOutInactivity();
        }
        // if(order_list_response && order_list_response.responseStatus.STATUS == "FAILED" && order_list_response.responseStatus.STATUSCODE == "201"){
        //     this.loggedOutInactivity();
        // }
        
        return ( 
            <>
            <div id="preloader" style={{display:"none"}}>{t("home")}</div>
            <CookieConsent
             buttonText={t("i_understand")}
             buttonStyle={{ background: "#f8b91f",color: "#fff" }}
             >
            {t("cookie_consent")}
            </CookieConsent>
            <header className="header_top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="">
                            <div className="logo"><Link to={`/${language}`}><img src={window.location.origin+"/assets/images/logo-theBoulangerie.png"} alt="" /></Link></div>
                        </div>
                        <div className=" navmainwrap">
                            <div className="nav_wrap">
                                <ResponsiveMenu
                                    menuOpenButton={<><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></>}
                                    menuCloseButton={<><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></>}
                                    changeMenuOn="500px"
                                    largeMenuClassName="large-menu-classname"
                                    smallMenuClassName="collapse-button"
                                    menu={
                                        <ul className="slimmenu">
                                            <li className={(this.state.page == 'home' ? 'active' : '')}><Link to={`/${language}`}>{t("home")}</Link> </li>
                                            <li className={(this.state.page == 'bakery' ? 'active' : '')}><Link to={`/${language}/bakeryList`}>{t("our_bakeries")}</Link></li>
                                            <li className={(this.state.page == 'whoAreWe' ? 'active' : '')}><Link to={`/${language}/who-are-we`}>{t("who_are_we")}</Link></li>
                                            <li className={(this.state.page == 'contactusUrl' ? 'active' : '')}><Link to={`/${language}/contact-us`}>{t("contact")}</Link></li>
                                        </ul>
                                    }
                                />
                            </div>
                            <div className="country_wrap">
                                <div className="countryouter" id="countrydrp" onClick={this.toggeleLangDrop}>
                                    {this.props.lng == "en" ? <img src={window.location.origin+"/assets/images/eng.png"} alt="" /> : <img src={window.location.origin+"/assets/images/fr.png"} alt="" />}
                                </div>
                                {this.state.showLangList ? <div className="inercountry">
                                    <a className="pointer" onClick={() => this.langSelectedHandler("en")} ><img src={window.location.origin+"/assets/images/eng.png"} alt="" /><span>{t("english")}</span></a>
                                    <a className="pointer" onClick={() => this.langSelectedHandler("fr")}><img src={window.location.origin+"/assets/images/fr.png"} alt="" /><span>{t("french")}</span></a>
                                </div> : null}
                            </div>
                        </div>
                        <div className="right_header">
                            {/* <a href="#" className="cart_shop"><i className="fa fa-shopping-cart" aria-hidden="true"></i></a>
                            <a href="#" className="login">{t("login")}</a> */}
                            {cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS" &&
                            cart_list_data.responseData.orderDetails.length > 0 &&
                            <Link to={`/${language}/cart`} className="cart_shop"><i className="fa fa-shopping-cart" aria-hidden="true"></i>
                            <span className="notificaion-count">{cart_list_data.responseData.orderDetails.length}</span>
                            </Link>}
                            {(!cart_list_data || cart_list_data.responseStatus.STATUS == "FAILED" ||
                            (cart_list_data.responseStatus.STATUS == "SUCCESS" &&
                            cart_list_data.responseData.orderDetails.length == 0)) &&
                            <Link to={`/${language}/cart`} className="cart_shop"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>}
                            
                            {loginDetails ? 
                            <div className="btn-group header_dropdown_btn">
                                <button type="button" className="btn btn-secondary dropdown-toggle" onClick={this.toggleDashboardDrop}>
                                    <i className="fa fa-user-o" aria-hidden="true"></i> <span>{t("my_account")}</span>
                                </button>
                                {this.state.showDashboardList && <div className="dropdown-menu dropdown-menu-right show">
                                <ul>
                                    <li><Link to={`/${language}/dashboard`}>{t("dashboard")}</Link></li>
                                    <li><a onClick={this.logout}>{t("logout")}</a></li>
                                </ul>
                                </div>}
                            </div> : 
                            <a onClick={this.redirectLoginPage} className="login">{t("login")}</a>}
                        </div>
                    </div>
                </div>
            </header>
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
      loginDetails: state.auth.login_details,
      language: state.setting.lng,
      cart_list_data: state.order.cart_list_data,
      customer_details_response: state.customer.customer_details_response,
      authorize_cart_response: state.order.authorize_cart_response,
      checkout_response: state.order.checkout_response,
      edit_profile_response: state.customer.edit_profile_response,
      edit_shipping_address_response: state.customer.edit_shipping_address_response,
      edit_billing_address_response: state.customer.edit_billing_address_response,
      change_password_response: state.customer.change_password_response,
      order_list_response: state.order.order_list_response,
      loadingOrder: state.order.loading,
      loadingCustomer: state.customer.loading,
      loadingAuth: state.auth.loading,

    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      changeLang: (lng) => dispatch(changeLang(lng)),
      cartList: payload => dispatch(cart_list.request(payload)),
      responseDismiss: () => dispatch(set_auth_state({ login_details: null })),
      customerResponseDismiss: () => dispatch(set_customer_state({ customer_details_response: null })),
      cartResponseDismiss: () => dispatch(set_order_state({ cart_list_data: null })),
      page: payload => dispatch(page(payload)),
      logout: payload => dispatch(logout.request(payload)),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  );
 
export default enhance(Header);