import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner2  from "../Banner/Banner2"
import {
    get_bakery_profile_data
} from "../../redux/reducers/bakery";
import "./BakeryProfile.css"

class BakeryProfile extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            images : [],
            lang : props.lang,
            isOpen : false,
            photoIndex: 0,
         }
    }
    componentDidMount(){
        this.getProfileData();
    }
    getProfileData = () => {
        const { match, getBakeryProfileData } = this.props;
        let id = match.params.id;
        if(id){
            getBakeryProfileData({encryptId: id});
        }
    }
    render() { 
        const { t, bakery_profile_data, lang, match, getBakeryProfileData } = this.props;
        const { photoIndex } = this.state;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                let id = match.params.id;
                if(id){
                    getBakeryProfileData({encryptId: id});
                }
            })
        }
        let arr = []
        if(bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" ){
            bakery_profile_data.responseData.galleries.map((val,key)=>(
                arr.push(val)
            ))
        }
        return ( 
            
            <>
            <Header />
            <Banner2 />
            <div className="detail_pg_profile_block_section">
                <div className="profile_wrapper">
                    <div className="detail_pg_profile">
                        <div className="dp_frame">
                        {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                            <img src={bakery_profile_data.responseData.chef_image} alt="" />
                        }
                        </div>
                        <div className="dp_info_area">
                            <h3>{bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                            bakery_profile_data.responseData.chef_name}</h3>
                            <h4>{t("the_baker")}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="detail_pg_contnt_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <div className="card-body card_big">
                                    <h3 className="card_big_title"><span>
                                    {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                        bakery_profile_data.responseData.name}
                                        </span> <span className="tag">{t("bakery")}</span></h3>
                                    <p><i className="fa fa-map-marker" aria-hidden="true"></i>
                                    {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                        " "+bakery_profile_data.responseData.address}
                                    </p>
                                    <p><i className="fa fa-phone" aria-hidden="true"></i>
                                    {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                        " "+bakery_profile_data.responseData.phone_no}
                                    </p>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">{t("presentation")}</div>
                                <div className="card-body">
                                    <p>
                                    {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                        bakery_profile_data.responseData.description}
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header"><i className="fa fa-picture-o" aria-hidden="true"></i> {t("gallery")}</div>
                                <div className="card-body">
                                    <ul className="gallery_list long">
                                    {arr.length > 0 && arr.map((val,index) => (
                                        <li key={index}><a onClick={() => this.setState({ isOpen : true, photoIndex : index})} ><img src={val} /></a></li>
                                    ))}
                                    {arr.length > 0 && this.state.isOpen && <Lightbox
                                        mainSrc={arr[photoIndex]}
                                        nextSrc={arr[(photoIndex + 1) % arr.length]}
                                        prevSrc={arr[(photoIndex + arr.length - 1) % arr.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + arr.length - 1) % arr.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % arr.length,
                                        })
                                        }
                                    />}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <div className="card-header"><i className="fa fa-clock-o" aria-hidden="true"></i> {t("hours")}</div>
                                <div className="card-body">
                                    <ul className="list-group list-group-flush normal">
                                    {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                   <div dangerouslySetInnerHTML={{ __html: bakery_profile_data.responseData.schedule }} />
                                        }
                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">Lundi <span>07:00-20:30</span></li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">Mardi <span>07:00-20:30</span></li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">Mercredi <span>07:00-20:30</span></li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">Jeudi <span>07:00-20:30</span></li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">Vendredi <span>07:00-20:30</span></li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">Samedi <span>07:00-20:30</span></li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">Dimanche <span>07:00-20:30</span></li> */}
                                    </ul>
                                </div>
                            </div>

                            
                            <div className="card">
                                <div className="card-header"><i className="fa fa-picture-o" aria-hidden="true"></i> {t("menus")}</div>
                                <div className="card-body">
                                    <ul className="gallery_list">
                                    {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && bakery_profile_data.responseData.menus.map((val)=>(
                                        <li key={val.id}>
                                            <a  href={val.path+'/'+val.menu} target="_blank">
                                                {val.type == "image" && <img src={val.path+'/'+val.menu} />}
                                                {val.type == "pdf" && <img src={val.pdf_image} />}
                                                
                                            </a>
                                        </li>
                                    ))}
                                     
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </>
         );
    }
}
const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        bakery_profile_data: state.bakery.bakery_profile_data,
        loading: state.bakery.loading,
        response: state.bakery.response,
        error: state.bakery.error,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getBakeryProfileData: payload => dispatch(get_bakery_profile_data.request(payload)),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

export default enhance(BakeryProfile);