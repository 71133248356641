import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces, Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { getSessionOrAuthorizedToken, roundOffDecimal } from '../../../Helper/Helper'
import {
    cart_list,set_order_state
} from "../../../redux/reducers/order";
import { deliverAmount } from '../../../config/Config';
import { Scrollbars } from 'react-custom-scrollbars';
import { changePrice } from '../../../redux/reducers/setting'

class OrderCartList extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
         }
    }
    totalPrice(count){
        const { t } = this.props
        if(count > 1){
            var ret = count +" "+ t("articles");
            
        }else{
            var ret = count +" "+ t("article")
        }
        return ret
        
    }
    render() { 
        const { t , cart_list_data , lang, cartList, loginDetails, deliveryCharge, changePrice, authorizeCartDataResponse,responseDismiss } = this.props;
        
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                let data = getSessionOrAuthorizedToken(loginDetails);
                cartList(data)
            })
        }
        if(authorizeCartDataResponse){
            responseDismiss()
            let data = getSessionOrAuthorizedToken(loginDetails);
            cartList(data)
        }
        let total = 0;
        if(cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS"){
            if(deliveryCharge){
                total = roundOffDecimal(parseFloat(cart_list_data.responseData.total_price)+ deliverAmount) 
            }else{
                total = roundOffDecimal(parseFloat(cart_list_data.responseData.total_price))
            }
            
        }
        changePrice(total);
        return ( 
            <>
            {cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS" &&
            <div className="col-lg-3">
                <div className="shadow_box product_booking_price_box fixed-position">
                    <h6 className="bakeryName_prlisting">{cart_list_data.responseData.bakery}</h6>
                    <h6 className="price_box_heading">{this.totalPrice(cart_list_data.responseData.orderDetails.length)} </h6>
                    <Scrollbars
                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                        style={{ height: 180 }}>
                    <ul className="cart_list_added_list">
                        {cart_list_data.responseData.orderDetails.map((val)=>(
                            <li key={val.order_detail_id}>
                                <div className="product_img"><a ><img src={val.product_img} alt="" /></a></div>
                                <div className="product_content_sec">
                                    <h4><a >{val.product_name}</a></h4>
                                    <div className="price_section">
                                        x{val.product_quantity} <span className="prodcut_price">{ roundOffDecimal(parseFloat(val.product_quantity) * parseFloat(val.product_price))} €</span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    </Scrollbars>
                    <ul className="cart_list_added_list total-cart">
                        <li>
                            <div className="product_content_sec flex">
                                <div className="heading-holder"><h4><a>{t("delivery_charge")} </a></h4></div>
                                <div className="price_section">
                                        <span className="prodcut_price">
                                        {deliveryCharge} €</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    
                    <ul className="booking_price">
                        <li>{t("total")} <span className="float-right">{total} €</span></li>
                    </ul>

                </div>
            </div>
            }
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        cart_list_data: state.order.cart_list_data,
        loginDetails: state.auth.login_details,
        authorizeCartDataResponse: state.order.authorize_cart_response,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        cartList: payload => dispatch(cart_list.request(payload)),
        changePrice: (val) => dispatch(changePrice(val)),
        responseDismiss: () => dispatch(set_order_state({ authorize_cart_response: null })),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
 
export default enhance(OrderCartList);