import api from "api";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    register, login , set_login_details , logout, set_auth_state, remember_me
} from "../reducers/auth";
import {authorize_cart_data
} from "../reducers/order";
import { cleanPayload, formatError } from "./utils";
import {addLoginDetails, CheckForSession, removeSessionId, removeLoginDetails} from "../../Helper/Helper";
import history from "../../history"

function* registerCustomerData({payload}){
  const lang = localStorage.getItem("i18nextLng");

  try {
    const res = yield api.post("/register",payload, {
      headers: { "x-lang": lang }
    });
    
    yield put(register.success({ register: res.data }));
  } catch (err) {
    yield put(register.failure({ error: formatError(err) }));
  }
}

function* watchRegisterCustomerData(){
  yield takeLatest(types.REGISTER.REQUEST, registerCustomerData);
}

function* loginCustomerData({payload}){
  const lang = localStorage.getItem("i18nextLng");

  try {
    const res = yield api.post("/login",payload, {
      headers: { "x-lang": lang }
    });
    
    yield put(login.success({ login_response: res.data }));
    const page = yield select(state => state.setting.page);
    
    if(res.data.responseStatus.STATUS == 'SUCCESS'){
      yield put(set_login_details(res.data.responseData)) // add login details to reducer
      addLoginDetails(res.data.responseData); //add login details to local storage
      let session_id = CheckForSession();
      yield put(authorize_cart_data.request({session_id : session_id }));
      yield put(set_auth_state({ remember_me_response: null }));
      removeSessionId();
      if(page){
        yield call(history.push, '/'+lang+'/checkout')  
      }else{
        yield call(history.push, '/'+lang+'/dashboard')
      }
      
    }
    
  } catch (err) {
    yield put(login.failure({ error: formatError(err) }));
  }
}

function* watchLoginCustomerData(){
  yield takeLatest(types.LOGIN.REQUEST, loginCustomerData);
}

function* logoutCustomerData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  let authorization = ''
  let loggedDetails = yield select(state => state.auth.login_details);
  
  if(loggedDetails){
    authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
  }
  try {
    const res = yield api.post("/logout",payload, {
      headers: { "x-lang": lang , "x-access-token" : authorization }
    });
    
    yield put(logout.success({ logout_response: res.data }));
  } catch (err) {
    yield put(logout.failure({ error: formatError(err) }));
  }
}

function* watchLogoutCustomerData(){
  yield takeLatest(types.LOGOUT.REQUEST, logoutCustomerData);
}

function* rememberMeData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  
  try {
    const res = yield api.post("/rememberMeData",payload, {
      headers: { "x-lang": lang }
    });
    
    yield put(remember_me.success({ remember_me_response: res.data }));
  } catch (err) {
    yield put(remember_me.failure({ error: formatError(err) }));
  }
}

function* watchRememberMeData(){
  yield takeLatest(types.REMEMBER_ME.REQUEST, rememberMeData);
}

export default function* (){
    yield all([
        watchRegisterCustomerData(),
        watchLoginCustomerData(),
        watchLogoutCustomerData(),
        watchRememberMeData()
    ]);
};