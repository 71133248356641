import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import  PopularBakery  from "./containers/PopularBakery/PopularBakery"
import  BakeryList  from "./containers/BakeryList/BakeryList"
import  BakeryProfile  from "./containers/BakeryProfile/BakeryProfile"
import  ProductList  from "./containers/ProductList/ProductList"
import  ProductDetails  from "./containers/ProductDetails/ProductDetails"
import  Cart  from "./containers/Cart/Cart"
import  Login  from "./containers/Auth/Login"
import  Register  from "./containers/Auth/Register"
import  Dashboard  from "./containers/Dashboard/Dashboard"
import  Checkout  from "./containers/Checkout/Checkout"
import  Thankyou  from "./containers/Thank-you/Thankyou"
import  ResetPassword  from "./containers/ResetPassword/ResetPassword"
import  WhoAreWe  from "./containers/WhoAreWe/WhoAreWe"
import  TermsOfUse  from "./containers/TermsOfUse/TermsOfUse"
import  PrivacyPolicy  from "./containers/PrivacyPolicy/PrivacyPolicy"
import  TermsAndCondition  from "./containers/TermsAndCondition/TermsAndCondition"
import  ContactUs  from "./containers/ContactUs/ContactUs"

const Router = ({ authorized, lang }) => {
    return authorized ? (
        <Switch>
            <Route exact path={`/${lang}/`}>
                <PopularBakery />
            </Route>
            <Route exact path={`/${lang}/bakeryList/:search?`}>
                <BakeryList />
            </Route>
            <Route exact path={`/${lang}/bakeryProfile/:id`}>
                <BakeryProfile />
            </Route>
            <Route exact path={`/${lang}/productList/:id`}>
                <ProductList />
            </Route>
            <Route exact path={`/${lang}/productDetails/:id`}>
                <ProductDetails />
            </Route>
            <Route exact path={`/${lang}/cart`}>
                <Cart />
            </Route>
            <Route exact path={`/${lang}/dashboard`}>
                <Dashboard />
            </Route>
            <Route exact path={`/${lang}/checkout`}>
                <Checkout />
            </Route>
            <Route exact path={`/${lang}/thank-you`}>
                <Thankyou />
            </Route>
            <Route exact path={`/${lang}/who-are-we`}>
                <WhoAreWe />
            </Route>
            <Route exact path={`/${lang}/terms-of-use`}>
                <TermsOfUse />
            </Route>
            <Route exact path={`/${lang}/privacy-policy`}>
                <PrivacyPolicy />
            </Route>
            <Route exact path={`/${lang}/terms-and-condition`}>
                <TermsAndCondition />
            </Route>
            <Route exact path={`/${lang}/contact-us`}>
                <ContactUs />
            </Route>
            <Route render={() => <Redirect to={`/${lang}/`} />} />
        </Switch>
    ) : (
        <Switch>
            <Route exact path={`/${lang}/`}>
                <PopularBakery />
            </Route>
            <Route exact path={`/${lang}/bakeryList/:search?`}>
                <BakeryList />
            </Route>
            <Route exact path={`/${lang}/bakeryProfile/:id`}>
                <BakeryProfile />
            </Route>
            <Route exact path={`/${lang}/productList/:id`}>
                <ProductList />
            </Route>
            <Route exact path={`/${lang}/productDetails/:id`}>
                <ProductDetails />
            </Route>
            <Route exact path={`/${lang}/cart`}>
                <Cart />
            </Route>
            <Route exact path={`/${lang}/login`}>
                <Login />
            </Route>
            <Route exact path={`/${lang}/register`}>
                <Register />
            </Route>
            <Route exact path={`/${lang}/reset-password/:token`}>
                <ResetPassword />
            </Route>
            <Route exact path={`/${lang}/who-are-we`}>
                <WhoAreWe />
            </Route>
            <Route exact path={`/${lang}/terms-of-use`}>
                <TermsOfUse />
            </Route>
            <Route exact path={`/${lang}/privacy-policy`}>
                <PrivacyPolicy />
            </Route>
            <Route exact path={`/${lang}/terms-and-condition`}>
                <TermsAndCondition />
            </Route>
            <Route exact path={`/${lang}/contact-us`}>
                <ContactUs />
            </Route>
            <Route render={() => <Redirect to={`/${lang}/`} />} />
        </Switch>
    );
    
}

export default Router;