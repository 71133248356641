import React, { Component } from 'react';
import { withNamespaces ,Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner3  from "../Banner/Banner3"

class TermsOfUse extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() { 
        const { t,lang } = this.props;
        return ( 
            <>
            <Header />
            <Banner3 />
            <div className="container">
            <div className="ab_wrapshadow">
            

            <div className="row">
                <div className="col-lg-12" >
                    
                    <>
                    <h3>{t("termsOfUse1")}</h3>
                    <p>{t("termsOfUse2")}<br/>
                        {t("termsOfUse3")} <a href={"maito:"+t("termsOfUse4")}>{t("termsOfUse4")}</a>
                    </p>
                    <h3>{t("termsOfUse5")}</h3>
                    <p>{t("termsOfUse6")}
                        <a href={t("termsOfUse7")}>{t("termsOfUse7")}</a> {t("termsOfUse8")}<br/>
                        {t("termsOfUse9")}<br/>
                        {t("termsOfUse10")}<br/>
                        {t("termsOfUse11")}<br/>
                        {t("termsOfUse12")}
                    </p>
                    <h3>{t("termsOfUse13")}</h3>
                    <p>{t("termsOfUse14")}</p>
                    <ul>
                        <li>{t("termsOfUse15")}</li>
                        <li>{t("termsOfUse16")}</li>
                    </ul>
                        <div className="iner_ab_listinggap">
                        <h3>{t("termsOfUse17")}</h3>
                        <p>{t("termsOfUse18")}<br/>
                        {t("termsOfUse19")}<br/>
                        {t("termsOfUse20")}</p>
                        <ul>
                            <li>{t("termsOfUse21")}</li>
                            <li>{t("termsOfUse22")}</li>
                            <li>{t("termsOfUse23")}</li>
                        </ul>
                        <p>
                        {t("termsOfUse24")}<br/>
                        {t("termsOfUse25")}
                        </p>
                        <ul>
                            <li>{t("termsOfUse26")}</li>
                            <li>{t("termsOfUse27")}</li>
                        </ul>
                        <p>
                        {t("termsOfUse28")}<br/>
                        {t("termsOfUse29")}
                        </p>
                        <h3>{t("termsOfUse30")}</h3>
                        <p>{t("termsOfUse31")}<br/>
                        {t("termsOfUse32")}<br/>
                        {t("termsOfUse33")}<br/>
                        {t("termsOfUse34")}<br/>
                        {t("termsOfUse35")}<br/>
                        {t("termsOfUse36")}</p>
                        <h3>{t("termsOfUse37")}</h3>
                        <p>{t("termsOfUse38")}</p>
                        <ul>
                            <li>{t("termsOfUse39")}</li>
                            <li>{t("termsOfUse40")}</li>
                            <li>{t("termsOfUse41")}</li>
                            <li>{t("termsOfUse42")}</li>
                            <li>{t("termsOfUse43")}</li>
                        </ul>
                        <p>
                        {t("termsOfUse44")}<br/>
                        {t("termsOfUse45")}<br/>
                        {t("termsOfUse46")}<br/>
                        {t("termsOfUse47")}<br/>
                        {t("termsOfUse48")}
                        </p>
                        <h3>{t("termsOfUse49")}</h3>
                        <p>{t("termsOfUse50")}<br/>
                        {t("termsOfUse51")}<br/>
                        {t("termsOfUse52")} <a href={"mailto:" + t("termsOfUse53")}>{t("termsOfUse53")}</a> 
                        {t("termsOfUse54")}</p>
                        <h3>{t("termsOfUse55")}</h3>
                        <p>{t("termsOfUse56")}<br/>
                        {t("termsOfUse57")}</p>
                        <h3>{t("termsOfUse58")}</h3>
                        <p>{t("termsOfUse59")}<br/>
                        {t("termsOfUse60")}<br/>
                        {t("termsOfUse61")}</p>
                        <h3>{t("termsOfUse62")}</h3>
                        <p>{t("termsOfUse63")}<br/>
                        {t("termsOfUse64")}</p>
                        <h3>{t("termsOfUse65")}</h3>
                        <p>{t("termsOfUse66")}<br/>
                        {t("termsOfUse67")}<br/>                        
                        {t("termsOfUse68")}<br/>
                        {t("termsOfUse69")}<br/>
                        {t("termsOfUse70")}<br/>
                        {t("termsOfUse71")} <a href={"mailto:" + t("termsOfUse53")}>{t("termsOfUse53")}</a> 
                        {t("termsOfUse72")}</p>
                        <h3>{t("termsOfUse73")}</h3>
                        <p>{t("termsOfUse74")} <a href={"mailto:" + t("termsOfUse53")}>{t("termsOfUse53")}</a>
                        {t("termsOfUse75")}<br/>
                        {t("termsOfUse76")} <a href={"mailto:" + t("termsOfUse53")}>{t("termsOfUse53")}</a>
                        {t("termsOfUse77")}<br/>
                        {t("termsOfUse78")}<br/>
                        {t("termsOfUse79")} <a href={"mailto:" + t("termsOfUse53")}>{t("termsOfUse53")}</a>
                        {t("termsOfUse80")}</p>
                        <h3>{t("termsOfUse81")}</h3>
                        <p>{t("termsOfUse82")}</p>
                    </div>
                    <h3>{t("termsOfUse83")}</h3>
                    <p>{t("termsOfUse84")}<br/>
                    {t("termsOfUse85")}<br/>
                    {t("termsOfUse86")}<br/>
                    {t("termsOfUse87")}<br/>
                    <b>{t("termsOfUse88")}</b>
                    {t("termsOfUse89")}</p>
                    <h3>{t("termsOfUse90")}</h3>
                    <p>{t("termsOfUse91")}<br/>
                    {t("termsOfUse92")}<br/>
                    {t("termsOfUse93")}<br/>
                    {t("termsOfUse94")} <a href={"mailto:" + t("termsOfUse53")}>{t("termsOfUse53")}</a><br/>
                    {t("termsOfUse95")}</p>
                    <h3>{t("termsOfUse96")}</h3>
                    <p>{t("termsOfUse97")}<br/>
                    {t("termsOfUse98")}<br/>
                    {t("termsOfUse99")}
                    </p>
                    <ul>
                        <li>{t("termsOfUse100")}</li>
                        <li>{t("termsOfUse101")}</li>
                        <li>{t("termsOfUse102")}</li>
                    </ul>
                    <p>
                    {t("termsOfUse103")}<br/>
                    {t("termsOfUse104")}<br/>
                    {t("termsOfUse105")}
                    </p>
                    <h3>{t("termsOfUse106")}</h3>
                    <p>{t("termsOfUse107")}</p>
                    <h3>{t("termsOfUse108")}</h3>
                    <p>{t("termsOfUse109")}<br/>
                    {t("termsOfUse110")}<br/>
                    {t("termsOfUse111")} <a href={"mailto:" + t("termsOfUse53")}>{t("termsOfUse53")}</a>
                    {t("termsOfUse112")}
                    </p>
                    <h3>{t("termsOfUse113")}</h3>
                    <p>{t("termsOfUse114")}</p>
                    <h3>{t("termsOfUse115")}</h3>
                    <div className="iner_ab_listinggap">
                        <h3>{t("termsOfUse116")}</h3>
                        <p>{t("termsOfUse117")}<br/>
                        {t("termsOfUse118")}<br/>
                        {t("termsOfUse119")}
                        </p>
                        <h3>{t("termsOfUse120")}</h3>
                        <p>{t("termsOfUse121")}<br/>
                        {t("termsOfUse122")}<br/>
                        {t("termsOfUse123")}<br/>
                        
                        {t("termsOfUse124")}<br/>
                        {t("termsOfUse125")}</p>
                    </div>
                    <h3>{t("termsOfUse126")}</h3>
                    <div className="iner_ab_listinggap">
                        <h3>{t("termsOfUse127")}</h3>
                        <p>{t("termsOfUse128")}</p>
                        <h3>{t("termsOfUse129")}</h3>
                        <p>{t("termsOfUse130")}</p>
                        <h3>{t("termsOfUse131")}</h3>
                        <p>{t("termsOfUse132")}</p>
                        <h3>{t("termsOfUse133")}</h3>
                        <p>{t("termsOfUse134")}</p>
                        <h3>{t("termsOfUse136")}</h3>
                        <p>{t("termsOfUse137")}</p>
                    </div>
                    <h3>{t("termsOfUse138")}</h3>
                    <p>{t("termsOfUse139")}</p>
                    </>
                </div>
            </div>
            </div>
            </div>
            <Footer />
            
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    }
  }

const enhance = compose(
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
export default enhance(TermsOfUse);