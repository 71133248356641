import React, { Component } from 'react';
import { withNamespaces ,Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner3  from "../Banner/Banner3"
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() { 
        const { t } = this.props;
        return ( 
            <>
            <Header />
            <Banner3 />
            <div className="product_detail_cart_area">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-7">
                            <div className="shadow_box log_box">
                                <div className="card-header">{t("reset_password")}</div>
                                <div className="card-body">
                                    <div className="row">
                                        
                                    </div>
                                    <ResetPasswordForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </>
         );
    }
}
 
export default withNamespaces()(ResetPassword);