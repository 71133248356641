import React, { Component } from 'react';
import './App.css';
import { withNamespaces } from 'react-i18next';
import Router from "./Router";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import ScrollToTop from 'react-router-scroll-top'
import { addLoginDetails } from './Helper/Helper'
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  set_auth_state
} from "./redux/reducers/auth";
import { Helmet } from 'react-helmet';
import {  meta_eng, meta_fr } from './config/Config'

class App extends Component {
      componentDidMount(){
        const { t, setLoginDetails } = this.props;
        let loggedDetails = addLoginDetails();
        if(Object.entries(loggedDetails).length > 0 && loggedDetails.constructor === Object){
          setLoginDetails(loggedDetails);
        }
      }
      render(){
        const { t, loginDetails, language } = this.props;
        let loggedDetails = addLoginDetails();
        let authorized = false;
        if(loginDetails){
          authorized = loginDetails.remember_token ? true : false;
        } 
        else if(loggedDetails){
          authorized = loggedDetails.remember_token ? true : false;
        }
        return (
            <>
              {language == 'en' &&
              <Helmet>
              <title>The Boulangerie</title>
              <meta http-equiv="content-language" content="en" />
              <meta name="description" content={meta_eng}/>
            </Helmet>}
              {language == 'fr' &&
              <Helmet>
              <title>The Boulangerie</title>
              <meta http-equiv="content-language" content="fr" />
              <meta name="description" content={meta_fr}/>
            </Helmet>}
              <ScrollToTop />
              <ReactNotification />
              <Router authorized={authorized} lang={language} />
            </>
          );
      } 
}

const mapStateToProps = state => {
  return {
      loginDetails: state.auth.login_details,
      language: state.setting.lng,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoginDetails: payload => dispatch(set_auth_state({ login_details: payload })),
  }
}

const enhance = compose(
  withNamespaces(),
  connect(
      mapStateToProps,
      mapDispatchToProps
  )
);

export default enhance(App);
