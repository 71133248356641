import api from "api";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    get_product_list_data,
    get_product_details_data
} from "../reducers/product";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* getProductListData( {payload} ){
    const lang = localStorage.getItem("i18nextLng");
    
    try {
        const res = yield api.get("/bakeryProduct/"+payload.encryptId, {
        headers: { "x-lang": lang }
        });

        yield put(get_product_list_data.success({ product_list_data: res.data }));
    } catch (err) {
        
        yield put(get_product_list_data.failure({ error: formatError(err) }));
    }
}

function* watchGetProductListData(){
    yield takeLatest(types.GET_PRODUCT_LIST_DATA.REQUEST, getProductListData);
}

function* getProductDetailData( {payload} ){
    const lang = localStorage.getItem("i18nextLng");
    
    try {
        const res = yield api.get("/productDetails/"+payload.encryptId, {
        headers: { "x-lang": lang }
        });

        yield put(get_product_details_data.success({ product_detail: res.data }));
    } catch (err) {
        
        yield put(get_product_details_data.failure({ error: formatError(err) }));
    }
}

function* watchGetProductDetailData(){
    yield takeLatest(types.GET_PRODUCT_DETAILS_DATA.REQUEST, getProductDetailData);
}

export default function* (){
    yield all([
        watchGetProductListData(),
        watchGetProductDetailData()
    ]);
};