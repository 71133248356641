import React, { Component } from 'react';
import { withNamespaces ,Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner3  from "../Banner/Banner3"

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() { 
        const { t,lang } = this.props;
        return ( 
            <>
            <Header />
            <Banner3 />
            <div className="container">
            <div className="ab_wrapshadow">

            <div className="row">
                <div className="col-lg-12" >
                    <>
                        <p>{t("privacyPolicy1")} 
                        <a href={t("privacyPolicy2")}>{t("privacyPolicy2")}</a>
                        {t("privacyPolicy3")}<br/>
                        {t("privacyPolicy4")}<br/>
                        {t("privacyPolicy5")}<br/>
                        {t("privacyPolicy6")}<br/>
                        </p>
                        <h3>{t("privacyPolicy7")}</h3>
                        <p>{t("privacyPolicy8")}<br/>
                        {t("privacyPolicy9")}<br/>
                        {t("privacyPolicy10")}</p>
                        <ul>
                            <li>{t("privacyPolicy11")}</li>
                            <li>{t("privacyPolicy12")}</li>
                            <li>{t("privacyPolicy13")}</li>
                            <li>{t("privacyPolicy14")}</li>
                        </ul>
                        <p>{t("privacyPolicy15")}<br/>
                        {t("privacyPolicy16")}</p>
                        <ul>
                            <li>{t("privacyPolicy17")}</li>
                            <li>{t("privacyPolicy18")}</li>
                            <li>{t("privacyPolicy19")}</li>
                        </ul>
                        <h3>{t("privacyPolicy20")}</h3>
                        <p>{t("privacyPolicy21")}<br/>
                        {t("privacyPolicy22")}</p>
                        <ul>
                            <li>{t("privacyPolicy23")}</li>
                            <li>{t("privacyPolicy24")}</li>
                            <li>{t("privacyPolicy25")}</li>
                            <li>{t("privacyPolicy26")}</li>
                            <li>{t("privacyPolicy27")}</li>
                        </ul>
                        <p>{t("privacyPolicy28")}</p>
                        <h3>{t("privacyPolicy29")}</h3>
                        <p>{t("privacyPolicy30")}<br/>
                        {t("privacyPolicy31")}<br/>
                        {t("privacyPolicy32")}</p>
                        <h3>{t("privacyPolicy33")}</h3>
                        <p>{t("privacyPolicy34")}<br/>
                        {t("privacyPolicy35")}<br/>
                        {t("privacyPolicy36")}<br/>
                        {t("privacyPolicy37")}</p>
                        <h3>{t("privacyPolicy38")}</h3>
                        <p><i>{t("privacyPolicy39")}</i></p>
                        <p>{t("privacyPolicy40")}<br/>
                        {t("privacyPolicy41")}</p>
                        <p><i>{t("privacyPolicy42")}</i></p>
                        <p>{t("privacyPolicy43")}<br/>
                        {t("privacyPolicy44")}<br/>
                        <b>{t("privacyPolicy45")}</b>
                        {t("privacyPolicy46")}
                        <br/>
                        {t("privacyPolicy47")}</p>
                        <h3>{t("privacyPolicy48")}</h3>
                        <p>{t("privacyPolicy49")}<br/>
                        {t("privacyPolicy50")}<br/>
                        {t("privacyPolicy51")}</p>
                        <ul>
                            <li>{t("privacyPolicy52")} <a href={"maito:"+t("privacyPolicy53")}>{t("privacyPolicy53")}</a></li>
                        </ul>
                        <p>{t("privacyPolicy54")}<br/>
                        {t("privacyPolicy55")} 
                        <a href={t("privacyPolicy56")}>{t("privacyPolicy56")}</a></p>
                        <h3>{t("privacyPolicy57")}</h3>
                        <p>{t("privacyPolicy58")}</p>
                    </>
                    
                </div>
            </div>
            </div>
            </div>
            <Footer />
            
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    }
  }

const enhance = compose(
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
export default enhance(PrivacyPolicy);