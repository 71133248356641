import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    componentDidMount(){
        let url = window.location.href;
        let termUrl = url.indexOf("terms-of-use");
        let privacyUrl = url.indexOf("privacy-policy");
        if(termUrl >= 0){
            this.setState({
                page : "term"
            })            
        }else if(privacyUrl >= 0){
            this.setState({
                page : "privacy"
            }) 
        }
    }
    render() { 
        const { t, lang } = this.props;
        return ( 
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 footer_text">
                            <p><a href="https://angeler.com" title="angeler" target="_blank">Angeler</a> © 2020 {t("all_rights_are_reserved")}</p>
                        </div>
                        <div className="col-md-6 footer_priv">
                        <Link className={(this.state.page == 'term' ? 'active' : '')} to={`/${lang}/terms-of-use`}>{t("terms_of_use")}</Link>
                        <Link className={(this.state.page == 'privacy' ? 'active' : '')} to={`/${lang}/privacy-policy`}>{t("privacy_policy")}</Link>
                        </div>
                        <div className="col-md-2 social">
                            <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                            {/* <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                            <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a> */}
                        </div>
                    </div>
                </div>
        
                <a id="back2Top" title="Back to top" href="#" style={{display: "block"}}><i className="fa fa-arrow-up" aria-hidden="true"></i></a>
            </footer>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
        mapStateToProps,
        mapDispatchToProps
      )
);

export default enhance(Footer);