import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
  REGISTER: createAsyncTypes("REGISTER"),
  LOGIN: createAsyncTypes("LOGIN"),
  LOGOUT: createAsyncTypes("LOGOUT"),
  REMEMBER_ME: createAsyncTypes("REMEMBER_ME"),
  SET_AUTH_STATE: "SET_AUTH_STATE",
  SET_LOGIN_DETAILS: "SET_LOGIN_DETAILS",
}

export const register = createAsyncActions("REGISTER");
export const login = createAsyncActions("LOGIN");
export const logout = createAsyncActions("LOGOUT");
export const remember_me = createAsyncActions("REMEMBER_ME");
export const set_auth_state = val => ({
  type: types.SET_AUTH_STATE,
  state: val
});
export const set_login_details = val => ({
  type: types.SET_LOGIN_DETAILS,
  state: val
});

const initialState = {
    login_response: null,
    logout_response: null,
    register: null,
    loading: null,
    loadingRegister: null,
    loadingLogin: null,
    remember_me_response: null,
    response: null,
    error: null,
    login_details: null
};

const auth = (state = initialState, action) => {
    switch (action.type) {  
      case types.SET_AUTH_STATE:
        return {
            ...state,
            ...action.state
        }; 
        case types.SET_LOGIN_DETAILS:
        return {
            ...state,
            login_details: action.state
        }; 
      case types.REGISTER.REQUEST:
        return {
          ...state,
          loadingRegister: action.type
        };
      case types.REGISTER.SUCCESS:
        return {
          ...state,
          register: action.payload.register,
          loadingRegister: null
        };
      case types.REGISTER.FAILURE:
        return {
          ...state,
          loadingRegister: null,
          error: action.payload.error
        };
      case types.LOGIN.REQUEST:
        return {
          ...state,
          loadingLogin: action.type
        };
      case types.LOGIN.SUCCESS:
        return {
          ...state,
          login_response: action.payload.login_response,
          loadingLogin: null
        };
      case types.LOGIN.FAILURE:
        return {
          ...state,
          loadingLogin: null,
          error: action.payload.error
        };
      case types.LOGOUT.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.LOGOUT.SUCCESS:
        return {
          ...state,
          logout_response: action.payload.logout_response,
          loading: null
        };
      case types.LOGOUT.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
      };
      case types.REMEMBER_ME.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.REMEMBER_ME.SUCCESS:
        return {
          ...state,
          remember_me_response: action.payload.remember_me_response,
          loading: null
        };
      case types.REMEMBER_ME.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
      };
      default:
          return state;
      }
};

export default auth