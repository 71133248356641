import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner2  from "../Banner/Banner2"
import {
    get_product_details_data, set_product_state
} from "../../redux/reducers/product";
import {
    set_bakery_state
} from "../../redux/reducers/bakery";
import {
    add_to_cart, set_order_state, cart_list
} from "../../redux/reducers/order";
import { CheckForSession, showNotification, getSessionOrAuthorizedToken } from '../../Helper/Helper'

class ProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            quantity : 1,
            valQuantity : 1,
            lang : props.lang
         }
        this.changeHandler = this.changeHandler.bind(this);
        this.addToCart = this.addToCart.bind(this);
    }
    componentDidMount(){
        const {  getProductDetailData, match } = this.props;
        let id = match.params.id;
        if(id){
            getProductDetailData({encryptId:id})
        }
    }
    changeHandler(type){
        if(type == "plus"){
            let q = this.state.quantity;
            q++;
            this.setState({
                quantity : q
            })
        }
        if(type == "minus"){
            if(this.state.quantity > 1){
                let q = this.state.quantity;
                q--;
                this.setState({
                    quantity : q
                })
            }
        }
    }
    addToCart(product_id,bakery_id, price ,quantity){
        const { t, addToCart  } = this.props;
        let session = CheckForSession();
        let data = {
            session_id : session,
            bakery_id : bakery_id,
            product_id : product_id,
            price : price,
            quantity : quantity
        }
        addToCart(data)
    }
    render() { 
        const { t , product_detail, lang , getProductDetailData, match, add_to_cart, responseDismiss, loadingOrder, cartList, loginDetails,
            bakery_profile_data, product_list_data, responseBakeryAdd, responseProductAdd} = this.props;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                let id = match.params.id;
                if(id){
                    getProductDetailData({encryptId:id})
                }
            })
        }

        if(add_to_cart && add_to_cart.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),t("added_to_cart"),'success');
            responseDismiss();
            let data = getSessionOrAuthorizedToken(loginDetails);
            cartList(data)
          }
            
          if(add_to_cart && add_to_cart.responseStatus.STATUS == "FAILED"){
            showNotification(t("error"),add_to_cart.responseStatus.MESSAGE,'danger');
            responseDismiss();
          }

        if(!bakery_profile_data){
            if(product_detail && product_detail.responseStatus.STATUS == "SUCCESS"){
                responseBakeryAdd({bakery_profile_data:product_detail.responseData.bakeryProfile})
            }
        }
        if(!product_list_data){
            if(product_detail && product_detail.responseStatus.STATUS == "SUCCESS"){
                responseProductAdd({product_list_data:product_detail.responseData.productList})
            }
        }
        return ( 
            <>
            <Header />
            <Banner2 />
            <div className="product_detail_cart_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="prod_cart_boxes_area">
                                <div className="cart_colum_box image_area">
                                {product_detail && product_detail.responseStatus.STATUS == "SUCCESS" && 
                                        
                                    <img src={product_detail.responseData.image} alt="" />
                                }
                                </div>
                                <div className="cart_colum_box product_info_area">
                                    <div className="cart_detail_area">
                                        
                                        <div>
                                            <div className="cart_detail_heading_area">
                                                <h4>
                                                {product_detail && product_detail.responseStatus.STATUS == "SUCCESS" && 
                                                product_detail.responseData.name}
                                                </h4>
                                                {/* <p>450 g</p> */}
                                            </div>
                                            <p>
                                            {product_detail && product_detail.responseStatus.STATUS == "SUCCESS" && 
                                                product_detail.responseData.description}
                                            </p>
                                        </div>

                                        <div>
                                            <div className="cart_detail_price_qty_area">
                                                <div className="incrsDcrsInput">
                                                    <a className="eventBtn dcrsBtn" title="dcrsBtn" onClick={() => this.changeHandler('minus')}><i className="fa fa-minus"
                                                            aria-hidden="true"></i></a>
                                                    <input type="text" value={this.state.quantity} readOnly
                                                        className="incrsDecrsValue"  />
                                                    <a className="eventBtn incrsBtn" title="incrsBtn" onClick={() => this.changeHandler('plus')}><i className="fa fa-plus"
                                                            aria-hidden="true"></i></a>
                                                </div>
                                                <div className="price_area">
                                                {product_detail && product_detail.responseStatus.STATUS == "SUCCESS" && 
                                                product_detail.responseData.price} €</div>
                                            </div>
                                            
                                            <div className="cart_detail_call_to_action_area">
                                                {/* <a className="btn btn-rounded-yellow">{t("add_to_cart")}</a> */}
                                                {loadingOrder ? 
                                                    product_detail && product_detail.responseStatus.STATUS == "SUCCESS" && 
                                                    <a className="btn btn-rounded-yellow disable-link"
                                                    >{t("add_to_cart")}</a> : 
                                                    product_detail && product_detail.responseStatus.STATUS == "SUCCESS" && 
                                                    <a className="btn btn-rounded-yellow"
                                                    onClick={() => this.addToCart(product_detail.responseData.id,product_detail.responseData.bakery_id,product_detail.responseData.price,this.state.quantity)}
                                                    >{t("add_to_cart")}</a>
                                                }
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        product_detail: state.product.product_detail,
        loading: state.product.loading,
        response: state.product.response,
        error: state.product.error,
        add_to_cart: state.order.add_to_cart,
        loadingOrder: state.order.loading,
        loginDetails: state.auth.login_details,
        bakery_profile_data: state.bakery.bakery_profile_data,
        product_list_data: state.product.product_list_data,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getProductDetailData: payload => dispatch(get_product_details_data.request(payload)),
        addToCart: payload => dispatch(add_to_cart.request(payload)),
        responseDismiss: () => dispatch(set_order_state({ add_to_cart: null })),
        cartList: payload => dispatch(cart_list.request(payload)),
        responseBakeryAdd: payload => dispatch(set_bakery_state(payload)),
        responseProductAdd: payload => dispatch(set_product_state(payload)),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
 
export default enhance(ProductDetails);