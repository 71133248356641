import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner3  from "../Banner/Banner3"
import {
    get_customer_details
} from "../../redux/reducers/customer";
import {
    order_list
} from "../../redux/reducers/order";
import { getSessionOrAuthorizedToken, roundOffDecimal,dateFormat } from '../../Helper/Helper';
import Modal from 'react-modal';
import ShippingAddressForm from './ShippingAddressForm/ShippingAddressForm';
import BillingAddressForm from './BillingAddressForm/BillingAddressForm';
import ChangePassord from './ChangePassord/ChangePassord';
import EditProfile from './EditProfile/EditProfile';
import { Scrollbars } from 'react-custom-scrollbars';

const customStyles = {
  content : {
    top                   : '15%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, 0%)'
  }
};

const customStyles_address = {
    content : {
        marginTop             : '50px',
        left                  : '50%',
        transform             : 'translate(-50%, 0%)'
    }
  };
 
Modal.setAppElement('#root')

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
            stateLoggedDetails : 'loading',
            changePasswordModal: false,
            editUserProfileModal : false,
            editShippingAddressModal : false,
            editBillingAddressModal : false
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    openModal(modal) {
        this.setState({[modal]: true});
    }
    
    closeModal(modal) {
        this.setState({[modal]: false});
    }
    getCustomerDetails = () => {
        const {loginDetails, customerDetails } = this.props;
        let data = getSessionOrAuthorizedToken(loginDetails);
        customerDetails(data)
    }
    render() { 
        const { t, customer_details_response, loginDetails, order_list_response, orderLists, lang } = this.props;
        if(this.state.stateLoggedDetails != loginDetails){
            this.setState({
                stateLoggedDetails : loginDetails
            },()=>{
                this.getCustomerDetails();
                let data = getSessionOrAuthorizedToken(loginDetails);
                orderLists(data)
            })
            
        }
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                let data = getSessionOrAuthorizedToken(loginDetails);
                orderLists(data)
            })
        }
        return ( 
            <>
            <Header />
            <Banner3 customerDetail = {customer_details_response} />
            <div className="profile_page_body_area">
                <div className="container">

                    <div className="row user-profile-row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header"><i className="fa fa-user-o" aria-hidden="true"></i> {t("profile_details")}</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="preview-info">
                                                <label><i className="preview-icon call"></i>
                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.phone : ""}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="preview-info">
                                                <label><i className="preview-icon email"></i><a >
                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.email : ""}
                                                    </a></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <a href="#" className="" data-toggle="modal" data-target="#change-password-modal" onClick={() => this.openModal('changePasswordModal')}><i className="fa fa-lock"></i> {t("change_password")}</a>
                                            <a href="#" className="pull-right" data-toggle="modal" data-target="#edit-user-profile-modal" onClick={() => this.openModal('editUserProfileModal')}><i className="fa fa-pencil"></i> {t("edit_profile")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header"><i className="icon-box location-pin"></i> {t("shipping_address")}</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="address_content_list">
                                                <label>{t("name")}</label>
                                                <p>
                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.shipping_first_name +" "+ customer_details_response.responseData.shipping_last_name : ""}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="address_content_list">
                                                <label>{t("address")}</label>
                                                <p>
                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.shipping_address : ""}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="address_content_list">
                                                <label>{t("postal_code")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.shipping_postal_code : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="address_content_list">
                                                <label>{t("country")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.shipping_country : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="address_content_list">
                                                <label>{t("city")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.shipping_city : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="address_content_list">
                                                <label>{t("phone")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.shipping_phone : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <a href="#" className="pull-right" data-toggle="modal" data-target="#edit-shipping-address-modal" onClick={() => this.openModal('editShippingAddressModal')}><i className="fa fa-pencil"></i> {t("edit")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header"><i className="icon-box location-pin"></i>{t("billing_address")}</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="address_content_list">
                                                <label>{t("name")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.billing_first_name +" "+ customer_details_response.responseData.billing_last_name : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="address_content_list">
                                                <label>{t("address")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.billing_address : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="address_content_list">
                                                <label>{t("postal_code")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.billing_postal_code : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="address_content_list">
                                                <label>{t("country")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.billing_country : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="address_content_list">
                                                <label>{t("city")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.billing_city : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="address_content_list">
                                                <label>{t("phone")}</label>
                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
                                                customer_details_response.responseData.billing_phone : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <a href="#" className="pull-right" data-toggle="modal" data-target="#edit-billing-address-modal" onClick={() => this.openModal('editBillingAddressModal')}><i className="fa fa-pencil"></i> {t("edit")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {order_list_response && order_list_response.responseStatus.STATUS == "SUCCESS" &&
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">{t("order_history")}</div>
                                <div className="card-body">
                                    <div className="table-responsive order-table">
                                    <Scrollbars
                                            style={{ height: 400 }}>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">{t("order_id")}</th>
                                                <th scope="col">{t("order_date")}</th>
                                                <th scope="col">{t("order_status")}</th>
                                                <th scope="col">{t("payment_type")}</th>
                                                <th scope="col" style={{textAlign:"left"}}>{t("total_price")} (€)</th>
                                                <th scope="col">{t("delivery_type")}</th>
                                                <th scope="col">{t("delivery_date")}</th>
                                            </tr>
                                            </thead>
                                            
                                           
                                                
                                            <tbody>
                                            {order_list_response.responseData.map((val) => (
                                                <tr key={val.id}>
                                                    <td>{val.code}</td>
                                                    <td>{val.order_date}</td>
                                                    <td>{val.order_status}</td>
                                                    <td>{val.payment_type }</td>
                                                    <td >{roundOffDecimal(val.total_price)}</td>
                                                    <td>{val.delivery_status}</td>
                                                    <td>{val.delivery_date}</td>
                                                </tr>
                                            ))}
                                            
                                            
                                            </tbody>
                                            </table>
                                            </Scrollbars>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <Footer />
            <Modal
            isOpen={this.state.changePasswordModal}
            onRequestClose={() => this.closeModal('changePasswordModal')}
            style={customStyles}
            contentLabel="Example Modal"
            ><div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle"><i className="fa fa-lock" aria-hidden="true"></i> {t("change_password")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={() => this.closeModal('changePasswordModal')}>
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <ChangePassord onClose={() => {
                    this.closeModal('changePasswordModal');
                    }} />
            </div></Modal>
            <Modal
            isOpen={this.state.editUserProfileModal}
            onRequestClose={() => this.closeModal('editUserProfileModal')}
            style={customStyles}
            contentLabel="Example Modal"
            >
 
 
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle"><i className="fa fa-user-o" aria-hidden="true"></i> {t("edit_profile_details")}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={() => this.closeModal('editUserProfileModal')}>
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <EditProfile onClose={() => {
                    this.closeModal('editUserProfileModal');
                    this.getCustomerDetails();
                    }} customerDetail = {customer_details_response} />
                </div>
        
            </Modal>
            <Modal
            isOpen={this.state.editShippingAddressModal}
            onRequestClose={() => this.closeModal('editShippingAddressModal')}
            style={customStyles_address}
            contentLabel="Example Modal"
            ><div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle"><i className="icon-box location-pin"></i> {t("edit_shipping_address")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={() => this.closeModal('editShippingAddressModal')}>
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <ShippingAddressForm onClose={() => {
                    this.closeModal('editShippingAddressModal');
                    this.getCustomerDetails();
                    }} customerDetail = {customer_details_response} />
            </div></Modal>
            <Modal
            isOpen={this.state.editBillingAddressModal}
            onRequestClose={() => this.closeModal('editBillingAddressModal')}
            style={customStyles_address}
            contentLabel="Example Modal"
            ><div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle"><i className="icon-box location-pin"></i> {t("edit_billing_address")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeModal('editBillingAddressModal')}>
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <BillingAddressForm onClose={() => {
                    this.closeModal('editBillingAddressModal');
                    this.getCustomerDetails();
                    }} customerDetail = {customer_details_response} />
            </div></Modal>
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        customer_details_response: state.customer.customer_details_response,
        order_list_response: state.order.order_list_response,
        loginDetails: state.auth.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        customerDetails: payload => dispatch(get_customer_details.request(payload)),
        orderLists: payload => dispatch(order_list.request(payload)),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
 
export default enhance(Dashboard);