import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
  GET_TOP_BAKERY_LIST_DATA: createAsyncTypes("GET_TOP_BAKERY_LIST_DATA"),
  GET_SEARCH_BAKERY_LIST_DATA: createAsyncTypes("GET_SEARCH_BAKERY_LIST_DATA"),
  GET_BAKERY_PROFILE_DATA: createAsyncTypes("GET_BAKERY_PROFILE_DATA"),
  POST_CUSTOMER_SEARCH_DATA: createAsyncTypes("POST_CUSTOMER_SEARCH_DATA"),
  SET_BAKERY_STATE: "SET_BAKERY_STATE",
};

export const get_top_bakery_list_data = createAsyncActions("GET_TOP_BAKERY_LIST_DATA");
export const get_search_bakery_list_data = createAsyncActions("GET_SEARCH_BAKERY_LIST_DATA");
export const get_bakery_profile_data = createAsyncActions("GET_BAKERY_PROFILE_DATA");
export const post_customer_search_data = createAsyncActions("POST_CUSTOMER_SEARCH_DATA");
export const set_bakery_state = val => ({
  type: types.SET_BAKERY_STATE,
  state: val
});

const initialState = {
    bakery_data: null,
    bakery_search_data: null,
    bakery_profile_data: null,
    post_customer_search_response: null,
    loading: null,
    response: null,
    error: null
};

const bakery = (state = initialState, action) => {
    switch (action.type) {  
      case types.SET_BAKERY_STATE:
        return {
            ...state,
            ...action.state
        }; 
      case types.GET_TOP_BAKERY_LIST_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_TOP_BAKERY_LIST_DATA.SUCCESS:
        return {
          ...state,
          bakery_data: action.payload.bakery_data,
          loading: null
        };
      case types.GET_TOP_BAKERY_LIST_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      case types.GET_SEARCH_BAKERY_LIST_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_SEARCH_BAKERY_LIST_DATA.SUCCESS:
        return {
          ...state,
          bakery_search_data: action.payload.bakery_search_data,
          loading: null
        };
      case types.GET_SEARCH_BAKERY_LIST_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
      };
      case types.GET_BAKERY_PROFILE_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_BAKERY_PROFILE_DATA.SUCCESS:
        return {
          ...state,
          bakery_profile_data: action.payload.bakery_profile_data,
          loading: null
        };
      case types.GET_BAKERY_PROFILE_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
      };
      case types.POST_CUSTOMER_SEARCH_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.POST_CUSTOMER_SEARCH_DATA.SUCCESS:
        return {
          ...state,
          post_customer_search_response: action.payload.post_customer_search_response,
          loading: null
        };
      case types.POST_CUSTOMER_SEARCH_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
      };
      default:
          return state;
      }
};

export default bakery;