import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import { withRouter, Link } from "react-router-dom";
import {
    cart_list, set_order_state, add_to_cart, delete_cart_product
} from "../../redux/reducers/order";
import {
    page
} from "../../redux/reducers/setting";
import { getSessionOrAuthorizedToken, showNotification, CheckForSession, roundOffDecimal } from '../../Helper/Helper'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.totalPrice = this.totalPrice.bind(this)
        
    }
    componentDidMount(){
        const { cartList, loginDetails } = this.props;
        let data = getSessionOrAuthorizedToken(loginDetails);
        cartList(data)
    }
    changeHandler(type , product_id,bakery_id, price ,quantity){
        const { t, addToCart  } = this.props;
        let session = CheckForSession();
        
        if(type == "plus"){
            let data = {
                session_id : session,
                bakery_id : bakery_id,
                product_id : product_id,
                price : price,
                quantity : 1
            }
            addToCart(data)
        }
        if(type == "minus"){
            if(parseInt(quantity) > 1){
                let data = {
                    session_id : session,
                    bakery_id : bakery_id,
                    product_id : product_id,
                    price : price,
                    quantity : -1
                }
                addToCart(data)
            }
        }

    }
    deleteProduct(id){
        const { deleteProductCart , t } = this.props;
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui shadow_box confirmation-popup'>
                  <h4>{t("are_you_sure")}</h4>
                  <p className="text-center">{t("want_to_remove_this_product")}</p>
                  <div className="pop-btn-wrap">
                  <button className="btn btn-rounded-yellow btn-sm" onClick={onClose}>{t("no")}</button>
                  <button className="btn btn-rounded-yellow btn-sm"
                    onClick={() => {
                    //   this.handleClickDelete();
                        deleteProductCart({order_detail_id:id})
                        onClose();
                    }}
                  >
                    {t("yes_remove_it")}
                  </button>
                  </div>
                </div>
              );
            }
          });
        
    }
    checkout = () => {
        const { page , loginDetails, history, lang } = this.props
        if(loginDetails){
            history.push(`/`+lang+`/checkout`);
        }else{
            page("cart");
            history.push(`/`+lang+`/login`);
        }
        
    }
    totalPrice(count){
        const { t } = this.props
        if(count > 1){
            var ret = count +" "+ t("articles");
            
        }else{
            var ret = count +" "+ t("article")
        }
        return ret
        
    }
    render() { 
        const { t , cartList, cart_list_data , lang, loadingOrder, add_to_cart, responseDismiss, 
            loadingDeleteProduct, responseDeleteDismiss, delete_cart_product_response, loginDetails } = this.props;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                let data = getSessionOrAuthorizedToken(loginDetails);
                cartList(data)
            })
        }
        if(add_to_cart && add_to_cart.responseStatus.STATUS == "SUCCESS"){
            // showNotification(t("success"),t("added_to_cart"),'success');
            responseDismiss();
            let data = getSessionOrAuthorizedToken(loginDetails);
            cartList(data)
          }
            
          if(add_to_cart && add_to_cart.responseStatus.STATUS == "FAILED"){
            showNotification(t("error"),add_to_cart.responseStatus.MESSAGE,'danger');
            responseDismiss();
          }
          
          if(delete_cart_product_response && delete_cart_product_response.responseStatus.STATUS == "SUCCESS"){
              
            // showNotification(t("success"),delete_cart_product_response.responseStatus.MESSAGE,'success');
            responseDeleteDismiss();
            let data = getSessionOrAuthorizedToken(loginDetails);
            cartList(data)
          }
        return ( 
            <>
            <Header />
            <div className="product_detail_cart_area">
                <div className="container">
                    <div className="row">
                        {cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS" &&
                        <>
                        <h3>{cart_list_data.responseData.bakery}</h3>
                        <div className="col-lg-9">
                            
                            <div className="shadow_box product_cart_item_list_box">
                                <div className="product_cart_item_list_area">
                                    <ul>
                                        {cart_list_data.responseData.orderDetails.map((val) => (
                                            <li key={val.order_detail_id}>
                                                <div className="prodct_item_pic_frame">
                                                    {/* <Link to={`/productDetails/${val.product_id}`}><img src={val.product_img} alt="" /></Link> */}
                                                    <a ><img src={val.product_img} alt="" /></a>
                                                    </div>
                                                <div className="prodct_item_info_area">
                                                    <div className="product_title_and_price_area">
                                                        {/* <h4><Link to={`/productDetails/${val.product_id}`}>{val.product_name}</Link></h4> */}
                                                        <h4><a>{val.product_name}</a></h4>
                                                        <div className="single_price"><span>{val.product_price}</span> €</div>
                                                    </div>
                                                    <div className="product_qty_cntrl_area">
                                                        <div className="product_qty_cntrl">
                                                            <div className="incrsDcrsInput">
                                                                {loadingOrder ? 
                                                                <a className="eventBtn dcrsBtn disable-link" title="dcrsBtn" ><i className="fa fa-minus" aria-hidden="true"></i></a> :
                                                                <a className="eventBtn dcrsBtn" title="dcrsBtn" onClick={() => this.changeHandler('minus',val.product_id,val.bakery_id, val.product_price ,val.product_quantity)}><i className="fa fa-minus" aria-hidden="true"></i></a>}
                                                                <input type="text" value={val.product_quantity} readOnly className="incrsDecrsValue" />
                                                                {loadingOrder ?
                                                                <a className="eventBtn incrsBtn disable-link" title="incrsBtn" ><i className="fa fa-plus" aria-hidden="true"></i></a> : 
                                                                <a className="eventBtn incrsBtn" title="incrsBtn" onClick={() => this.changeHandler('plus',val.product_id,val.bakery_id, val.product_price ,val.product_quantity)}><i className="fa fa-plus" aria-hidden="true"></i></a>}
                                                            </div>
                                                            <a className="product_del_btn" onClick={() => this.deleteProduct(val.order_detail_id)}><i className="fa fa-trash" aria-hidden="true"></i></a>
                                                        </div>
                                                        <div className="product_updated_prize"><span>{ roundOffDecimal(parseFloat(val.product_quantity) * parseFloat(val.product_price))}</span> €</div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
        
                                    </ul>
                                </div>
                            </div>
                            <div className="product_cart_cta_area">
                                <a className="btn btn-rounded-yellow" onClick={this.checkout}>{t("continue_my_purchases")}</a>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="shadow_box product_booking_price_box">
                                <h6 className="price_box_heading">{t("price_details")}</h6>
                                <ul className="cart_list_added_list">
                                    <li>
                                        <div className="product_content_sec flex">
                                            <div className="heading-holder"><h4><a>{this.totalPrice(cart_list_data.responseData.orderDetails.length)} </a></h4></div>
                                            <div className="price_section">
                                                 <span className="prodcut_price">
                                                    {roundOffDecimal(parseFloat(cart_list_data.responseData.total_price))} €</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="booking_price">
                                    <li>{t("total")} <span className="float-right">{roundOffDecimal(parseFloat(cart_list_data.responseData.total_price))} €</span></li>
                                    
                                </ul>
        
                            </div>
                        </div>
                        </>
                        }
                        {(!cart_list_data || cart_list_data.responseStatus.STATUS == "FAILED") &&  
                            <div className="col-md-12" ><h4 style={{textAlign: "center"}}>{t("no_item_found")}</h4></div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        add_to_cart: state.order.add_to_cart,
        loadingOrder: state.order.loading,
        cart_list_data: state.order.cart_list_data,
        loadingDeleteProduct: state.order.loadingDeleteProduct,
        delete_cart_product_response: state.order.delete_cart_product_response,
        loginDetails: state.auth.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        page: payload => dispatch(page(payload)),
        deleteProductCart: payload => dispatch(delete_cart_product.request(payload)),
        addToCart: payload => dispatch(add_to_cart.request(payload)),
        cartList: payload => dispatch(cart_list.request(payload)),
        responseDismiss: () => dispatch(set_order_state({ add_to_cart: null })),
        responseDeleteDismiss: () => dispatch(set_order_state({ delete_cart_product_response: null })),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(Cart);