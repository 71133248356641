import { all } from "redux-saga/effects";
import bakery from "./bakery";
import product from "./product";
import auth from "./auth";
import order from "./order";
import customer from "./customer";

 function* root() {
  yield all([
    bakery(),
    product(),
    auth(),
    order(),
    customer()
  ]);
}
export default root;