import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces, Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from 'yup';
import { phoneValidator, showNotification } from '../../../Helper/Helper';
import {
    password_regen, set_customer_state
} from "../../../redux/reducers/customer";


class PasswordRegen extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
            baseUrl : window.location.origin + '/' + process.env.REACT_APP_TEST_FOLDER
         }
         this.resetFormData = ''
    }
    render() { 
        const { t, lang, loading, password_regen_response, PasswordRegen, onClose, responseDismiss } =this.props;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                this.resetFormData()
            })
        }
        if(password_regen_response && password_regen_response.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),password_regen_response.responseStatus.MESSAGE,'success');
            responseDismiss();
            onClose();  
        }else if(password_regen_response && password_regen_response.responseStatus.STATUS == "FAILED"){
            let error_msg = password_regen_response.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseDismiss();
        }
        const initialValues = {
              email : '',
        };
        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email(t('invalid_email_address'))
                .required(t('required')),
        });
        return ( 
            <Formik
            // enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, form) => {
                // console.log(values)
                values.baseUrl = this.state.baseUrl
                PasswordRegen(values)
            }}>
            {formikProps => {
                this.resetFormData = formikProps.resetForm
              const {
                values,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = formikProps;
              return ( 
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>{t("enter_your_registered_email")}</label><input type="text" className="form-control" 
                                name="email" onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email} />
                                {errors.email ? (
                                    <div className="error-msg">{errors.email}</div>
                                ) : null}
                            </div>
                            <div className="form-group text-center">
                                {loading ? 
                                <button className="btn btn-rounded-yellow disable-link" disabled type="submit">{t("submit")}</button>:
                                <button className="btn btn-rounded-yellow" type="submit">{t("submit")}</button>}
                            </div>
                        </div>
                    </form>
                    );
                }}
            </Formik>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        loading: state.customer.loading,
        password_regen_response: state.customer.password_regen_response,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        PasswordRegen: payload => dispatch(password_regen.request(payload)),
        responseDismiss: () => dispatch(set_customer_state({ password_regen_response: null })),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(PasswordRegen);