import api from "api";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    get_top_bakery_list_data,
    get_search_bakery_list_data,
    get_bakery_profile_data,
    post_customer_search_data
} from "../reducers/bakery";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* getTopBakeryListData(){
  const lang = localStorage.getItem("i18nextLng");

  try {
    const res = yield api.get("/topBakeryLists/", {
      headers: { "x-lang": lang }
    });

    yield put(get_top_bakery_list_data.success({ bakery_data: res.data }));
  } catch (err) {
    yield put(get_top_bakery_list_data.failure({ error: formatError(err) }));
  }
}

function* watchGetTopBakeryListData(){
  yield takeLatest(types.GET_TOP_BAKERY_LIST_DATA.REQUEST, getTopBakeryListData);
}

function* getSearchBakeryListData( {payload} ){
  const lang = localStorage.getItem("i18nextLng");
  try {
    let url = '';
    if(Object.keys(payload).length === 0 && payload.constructor === Object){
      url = "/searchBakery";
      if('page' in payload){
        url += '?page='+payload.page
      }
    }else{
      url = "/searchBakery?search="+payload.search;
      if('page' in payload){
        url += '&page='+payload.page
      }
    }
    
    const res = yield api.get(url, {
      headers: { "x-lang": lang }
    });

    yield put(get_search_bakery_list_data.success({ bakery_search_data: res.data }));
  } catch (err) {
    yield put(get_search_bakery_list_data.failure({ error: formatError(err) }));
  }
}

function* watchGetSearchBakeryListData(){
  yield takeLatest(types.GET_SEARCH_BAKERY_LIST_DATA.REQUEST, getSearchBakeryListData);
}

function* getBakeryProfileData( {payload} ){
  const lang = localStorage.getItem("i18nextLng");
  try {
    const res = yield api.get("/bakeryProfile/"+payload.encryptId, {
      headers: { "x-lang": lang }
    });

    yield put(get_bakery_profile_data.success({ bakery_profile_data: res.data }));
  } catch (err) {
    yield put(get_bakery_profile_data.failure({ error: formatError(err) }));
  }
}

function* watchGetBakeryProfileData(){
  yield takeLatest(types.GET_BAKERY_PROFILE_DATA.REQUEST, getBakeryProfileData);
}

function* postCustomerSearchData( {payload} ){
  const lang = localStorage.getItem("i18nextLng");
  let authorization = ''
  let loggedDetails = yield select(state => state.auth.login_details);
  
  if(loggedDetails){
    authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
  }
  try {
    const res = yield api.post("/customerSearchData",payload, {
      headers: { "x-lang": lang ,"x-access-token" : authorization}
    });

    yield put(post_customer_search_data.success({ post_customer_search_response: res.data }));
  } catch (err) {
    yield put(post_customer_search_data.failure({ error: formatError(err) }));
  }
}

function* watchPostCustomerSearchData(){
  yield takeLatest(types.POST_CUSTOMER_SEARCH_DATA.REQUEST, postCustomerSearchData);
}

export default function* (){
    yield all([
      watchGetTopBakeryListData(),
      watchGetSearchBakeryListData(),
      watchGetBakeryProfileData(),
      watchPostCustomerSearchData()
    ]);
};