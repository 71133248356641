import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import './Banner.css'

class Banner2 extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            page : "",
            banner_class : ""
         }
    }
    componentDidMount(){
        let url = window.location.href;
        let productDetailUrl = url.indexOf("productDetails");
        let productUrl = url.indexOf("productList");
        let bakeryProfileUrl = url.indexOf("bakeryProfile");
        
        if(bakeryProfileUrl >= 0){
            this.setState({
                page : "bakeryProfile",
                banner_class : "inner_page_banner detail_page_banner background"
            })            
        }
        else if(productUrl >= 0){
            this.setState({
                page : "productList",
                banner_class : "inner_page_banner"
            })
        }
        else if(productDetailUrl >= 0){
            this.setState({
                page : "productDetail",
                banner_class : "inner_page_banner"
            })
        }else{
            this.setState({
                page : "",
                banner_class : "inner_page_banner"
            })
        } 
        
    }
    render() { 
        const { t, bakery_profile_data, loginDetails, lang } = this.props;
        
        return ( 
            <section className={this.state.banner_class} >
            {this.state.page != "" && bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" &&
                <div className="banner-overlay-image"><img  src={`${bakery_profile_data.responseData.banner_image}`} /></div> }
                {(!bakery_profile_data || bakery_profile_data.responseStatus.STATUS == "FAILED" || this.state.page == "") && 
                <div className="banner-overlay-image"><img  src={window.location.origin+"/assets/images/banner1.png"} /></div> }
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.page == "bakeryProfile" && <div className="detail_page_banner_actn_area">
                                <p className="btn"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                 {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && bakery_profile_data.responseData.address}
                                </p>
                                {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                <Link to={`/${lang}/productList/${bakery_profile_data.responseData.id}`} className="btn btn-yellow btn-outlined">
                                <i className="fa fa-shopping-cart"></i> 
                                
                                    {t("order")}
                                </Link>
                                }
                                
                                {/*<a href="#" className="btn btn-dark-yellow btn-outlined"><i className="fa fa-trophy"></i> {t("consult_our_loyalty_offers")}</a>*/}
                            </div>}
                            {this.state.page == "productList" && 
                            <>
                            <h3 className="banner_heading">
                                {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                        bakery_profile_data.responseData.name}
                            </h3>
                            <div className="breadcrumb_area">
                                <ul>
                                {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                <li><Link to={`/${lang}/bakeryProfile/${bakery_profile_data.responseData.id}`}>{t("bakery")}</Link></li>}
                                    <li>{t("product")}</li>
                                </ul>
                            </div>
                            </>
                            }
                            {this.state.page == "productDetail" && 
                            <>
                            <h3 className="banner_heading">
                                {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                        bakery_profile_data.responseData.name}
                            </h3>
                            <div className="breadcrumb_area">
                                <ul>
                                {bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                <li><Link to={`/${lang}/bakeryProfile/${bakery_profile_data.responseData.id}`}>{t("bakery")}</Link></li>}
                                <li>{bakery_profile_data && bakery_profile_data.responseStatus.STATUS == "SUCCESS" && 
                                <Link to={`/${lang}/productList/${bakery_profile_data.responseData.id}`}>{t("product")}</Link>}</li>
                                <li>{t("details")}</li>
                                </ul>
                            </div>
                            </>
                            }
                            {this.state.page == "" && 
                            <h3 className="banner_heading">{loginDetails && loginDetails.first_name}{" "} 
                            {loginDetails && loginDetails.last_name}
                            </h3>}
                        </div>
                    </div>
                </div>
            </section>
         );
    }
}
const mapStateToProps = state => {
    return {
        bakery_profile_data: state.bakery.bakery_profile_data,
        loading: state.bakery.loading,
        response: state.bakery.response,
        error: state.bakery.error,
        loginDetails: state.auth.login_details,
        lang: state.setting.lng,
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
    }
}

const enhance = compose(
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

 
export default enhance(Banner2);