import React, { Component } from 'react';
import { withNamespaces ,Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner3  from "../Banner/Banner3"

class WhoAreWe extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() { 
        const { t, lang } = this.props;
        return ( 
            <>
            <Header />
            <Banner3 />
            <div className="container">
            <div className="ab_wrapshadow">
            

            <div className="row">
                <div className="col-lg-12">
                <>
                    <h4>{t("whoAreWe1")}</h4>
                    <h3>{t("whoAreWe2")}</h3>
                    <p>{t("whoAreWe3")}</p>
                    <h3>{t("whoAreWe4")}</h3>
                    <p>{t("whoAreWe5")}</p>
                    <h3>{t("whoAreWe6")}</h3>
                    <p>{t("whoAreWe7")}</p>
                    <h3>{t("whoAreWe8")}</h3>
                    <p>{t("whoAreWe9")}</p>
                </>
                </div>
            </div>
            </div>
            </div>
            <Footer />
            
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    }
  }

const enhance = compose(
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(WhoAreWe);