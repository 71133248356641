import React, { Component } from 'react';
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import { withRouter, Link } from "react-router-dom";
import { withNamespaces, Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";

class Thankyou extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const { t, lang } = this.props;
        return ( 
            <>
            <Header />
            <div className="thankyou-page_detail_area">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-7 text-center">
                            <div className="booking-confirm"><i className="fa fa-check" aria-hidden="true"></i></div>
                            <h2 className="marginbottom-10">{t("thankyou")}</h2>
                            <p className="marginbottom-30">{t("thankyou_msg")}</p>
                                <Link to={`/${lang}`} className="btn btn-rounded-yellow">{t("home")}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
 
export default enhance(Thankyou);