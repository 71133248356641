import React, { Component } from 'react';
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  CheckoutForm  from "./CheckoutForm/CheckoutForm"
import { compose } from "recompose";
import { connect } from "react-redux";
import {
    get_customer_details
} from "../../redux/reducers/customer";
import { getSessionOrAuthorizedToken } from '../../Helper/Helper'

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            stateLoggedDetails : 'loading'
         }
    }
    
    render() { 
        const { customer_details_response, loginDetails, customerDetails } = this.props;
        if(this.state.stateLoggedDetails != loginDetails){
            this.setState({
                stateLoggedDetails : loginDetails
            },()=>{
                let data = getSessionOrAuthorizedToken(loginDetails);
                customerDetails(data)
            })
            
        }
        return ( 
            <>
            <Header />
            <div className="product_detail_cart_area">
                <div className="container">
                    {customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS" && <CheckoutForm customerDetail={customer_details_response} />}
                    {(!customer_details_response || (customer_details_response && customer_details_response.responseStatus.STATUS == "FAILED")) && <CheckoutForm customerDetail={customer_details_response} />}
                    
                </div>
            </div>
            <Footer />
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        customer_details_response: state.customer.customer_details_response,
        loginDetails: state.auth.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        customerDetails: payload => dispatch(get_customer_details.request(payload)),
    }
  }

const enhance = compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(Checkout);