import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from 'yup';
import {
    login, set_auth_state, remember_me
} from "../../../redux/reducers/auth";
import { showNotification, getRememberToken } from '../../../Helper/Helper'

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
        };
        this.resetFormData = '';
    }
    render() { 
        const { t , loginData, login_response, history, responseDismiss, lang, loadingLogin, rememberMeApi, remember_me_response, responseRememberMeResponse, openModal} = this.props;
        if(!remember_me_response){
            rememberMeApi({remember_me_token :getRememberToken() })
        }

        const initialValues = {
              email: (remember_me_response && remember_me_response.responseStatus.STATUS == "SUCCESS") ? remember_me_response.responseData.email : '',
              password : (remember_me_response && remember_me_response.responseStatus.STATUS == "SUCCESS") ? remember_me_response.responseData.password : '',
              remember_me : (remember_me_response && remember_me_response.responseStatus.STATUS == "SUCCESS") ? true : false
        };
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                this.resetFormData()
            })
        }
        if(login_response && login_response.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),t("successfully_logged_in"),'success');
            responseDismiss();
            // responseRememberMeResponse();
            // history.push(`/login`);
        }else if(login_response && login_response.responseStatus.STATUS == "FAILED"){
            let error_msg = login_response.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseDismiss();
        }
        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email(t('invalid_email_address'))
                .required(t('required')),
            password: Yup.string()
                .min(8, t("password_should_be_8_chars_or_more"))
                .required(t("required")),
        });
          
        return ( 
        <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, form) => {
          loginData(values);
        }}>
        {formikProps => {
            this.resetFormData = formikProps.resetForm
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label>{t("email")}</label><input type="text" className="form-control" 
                            name="email" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email} />
                            {errors.email ? (
                                <div className="error-msg">{errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label>{t("password")}</label><input type="password" className="form-control" 
                            name="password" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password} />
                            {errors.password ? (
                                <div className="error-msg">{errors.password}</div>
                            ) : null}
                        </div>
                    </div>
                    
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="custom_checkbox"><input type="checkbox" 
                                    name="remember_me"
                                    onChange={handleChange}
                                    value="1"
                                    checked={values.remember_me}
                                    /><span className="chk_icon"></span>{t("remember_me")}</label>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 text-right">
                                <div className="form-group">
                                    <a onClick={() => openModal('forgotPasswordModal')}>{t("forgot_password")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="form-group">
                            {loadingLogin ? 
                            <button className="btn btn-rounded-yellow disable-link" disabled type="submit">{t("login")}</button> :
                            <button className="btn btn-rounded-yellow" type="submit">{t("login")}</button>}
                        </div>
                        <div className="form-group">
                            <p>
                                <Trans i18nKey="do_you_have_an_account">
                                    Do not have an account? <Link to={`/${lang}/register`}>Signup</Link>
                                </Trans>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </form>
          );
        }}
        </Formik>
      );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        login_response: state.auth.login_response,
        remember_me_response: state.auth.remember_me_response,
        loadingLogin: state.auth.loadingLogin,
        response: state.auth.response,
        error: state.auth.error,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        loginData: payload => dispatch(login.request(payload)),
        rememberMeApi: payload => dispatch(remember_me.request(payload)),
        responseDismiss: () => dispatch(set_auth_state({ login_response: null })),
        responseRememberMeResponse: () => dispatch(set_auth_state({ remember_me_response: null })),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

export default enhance(LoginForm);