import React, { Component } from 'react';
import { withNamespaces ,Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner3  from "../Banner/Banner3"

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() { 
        const { t,lang } = this.props;
        return ( 
            <>
            <Header />
            <Banner3 />
            <div className="profile_page_body_area">
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="shadow_box marginbottom-35">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12">
                                        &nbsp;
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="contact_info_area icon-gap">
                                            <i className="icon-box location-pin"></i>
                                            <div><p>207 A Thomson road<br/>Goldhill Centre<br/>Singapore - 307640</p></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        {/*<div className="contact_info_area">
                                            <i className="fa fa-phone"></i>
                                            <div><p>01.23.26.33406</p></div>
                                        </div>*/}
                                        <div className="contact_info_area">
                                            <i className="fa fa-envelope-o"></i>
                                            <div><a href="mailto:contact@theboulangerie.fr">contact@theboulangerie.fr</a></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <div className="col-lg-4 text-center">
                            <img src={window.location.origin+"/assets/images/mail-icon.png"} alt="" className="mail-icon" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="map_area">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.047261006866!2d103.8432381!3d1.3185799!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8cd13dcc65df49b9!2sGoldhill%20Centre%20Eateries!5e0!3m2!1sen!2sin!4v1587538763994!5m2!1sen!2sin" width="600" height="450" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <Footer />
            
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    }
  }

const enhance = compose(
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
export default enhance(ContactUs);