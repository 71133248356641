import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from 'yup';
import {
    reset_password, set_customer_state
} from "../../../redux/reducers/customer";
import { showNotification, getRememberToken } from '../../../Helper/Helper'

class ResetPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
            token : ''
        };
        this.resetFormData = '';
    }
    componentDidMount(){
        const { match, ResetPassword } = this.props;
        let token = match.params.token;
        if(token){
            this.setState({token: token},()=>{
                ResetPassword({token:token,check_token:true})
            });
        }
    }
    render() { 
        const { t , ResetPassword, reset_password_response, history, responseDismiss, lang, loading} = this.props;
        
        const initialValues = {
            password : '',
            password_confirmation : ''
        };
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                this.resetFormData()
            })
        }
        if(reset_password_response && reset_password_response.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),reset_password_response.responseStatus.MESSAGE,'success');
            responseDismiss();
            // responseRememberMeResponse();
            history.push(`/`+lang+`/login`);
        }else if(reset_password_response && reset_password_response.responseStatus.STATUS == "FAILED"){
            let error_msg = reset_password_response.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseDismiss();
            if(reset_password_response.responseStatus.STATUSCODE == "217"){
                history.push(`/`+lang+`/login`);
            }
        }
        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .min(8, t("password_should_be_8_chars_or_more"))
                .required(t("required")),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref("password"), null], t("password_not_match"))
                .required(t("required")),
        });
          
        return ( 
        <Formik
        // enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, form) => {
            values.token = this.state.token
            ResetPassword(values);
        }}>
        {formikProps => {
            this.resetFormData = formikProps.resetForm
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label>{t("password")}</label><input type="password" className="form-control" 
                            name="password" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password} />
                            {errors.password ? (
                                <div className="error-msg">{errors.password}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label>{t("password_confirmation")}</label><input type="password" className="form-control" 
                            name="password_confirmation" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password_confirmation} />
                            {errors.password_confirmation ? (
                                <div className="error-msg">{errors.password_confirmation}</div>
                            ) : null}
                        </div>
                    </div>
                    
                    <div className="col-lg-12 text-center">
                        <div className="form-group">
                            {loading ? 
                            <button className="btn btn-rounded-yellow disable-link" disabled type="submit">{t("submit")}</button> :
                            <button className="btn btn-rounded-yellow" type="submit">{t("submit")}</button>}
                        </div>
                    </div>
                </div>
            </form>
          );
        }}
        </Formik>
      );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        reset_password_response: state.customer.reset_password_response,
        loading: state.customer.loading,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        ResetPassword: payload => dispatch(reset_password.request(payload)),
        responseDismiss: () => dispatch(set_customer_state({ reset_password_response: null })),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

export default enhance(ResetPasswordForm);