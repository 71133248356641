import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from 'yup';
import "./RegisterForm.css"
import {
    register, set_auth_state, login
} from "../../../redux/reducers/auth";
import { showNotification } from '../../../Helper/Helper'


class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
            email : '',
            password : ''
        };
        this.resetFormData = ''
    }
    render() { 
        const { t , registerData, register, history, responseDismiss, lang, loadingRegister, loginData, login_response, responseLoginDismiss} = this.props;
        const initialValues = {
            first_name: '',
              last_name: '',
              email: '',
              password : '',
              password_confirmation : '',
              check : ''
          };
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                this.resetFormData()
            })
        }
        if(register && register.responseStatus.STATUS == "SUCCESS"){
            // showNotification(t("success"),t("successfully_registered"),'success');
            responseDismiss();
            // history.push(`/login`);
            let loginCredentials = {
                email: this.state.email,
                password : this.state.password
            }
            loginData(loginCredentials);
        }else if(register && register.responseStatus.STATUS == "FAILED"){
            let error_msg = register.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseDismiss();
        }

        if(login_response && login_response.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),t("successfully_logged_in"),'success');
            responseLoginDismiss();
            // history.push(`/login`);
        }else if(login_response && login_response.responseStatus.STATUS == "FAILED"){
            let error_msg = login_response.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseLoginDismiss();
        }
        const validationSchema = Yup.object().shape({
            first_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t('required')),
            last_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t('required')),
            email: Yup.string()
                .email(t('invalid_email_address'))
                .required(t('required')),
            password: Yup.string()
                .min(8, t("password_should_be_8_chars_or_more"))
                .required(t("required")),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref("password"), null], t("password_not_match"))
                .required(t("required")),
            check: Yup.string()
                .required(t('required')),
        });
          
        return ( 
        <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, form) => {
            this.setState({
                email : values.email,
                password : values.password
            })
          registerData(values);
        }}>
        {formikProps => {
            this.resetFormData = formikProps.resetForm
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>{t("first_name")}</label>
                            <input type="text" className="form-control" name="first_name" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name} />
                            {errors.first_name ? (
                                <div className="error-msg">{errors.first_name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>{t("last_name")}</label><input type="text" className="form-control" 
                            name="last_name" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name} />
                            {errors.last_name ? (
                                <div className="error-msg">{errors.last_name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label>{t("email")}</label><input type="text" className="form-control" 
                            name="email" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email} />
                            {errors.email ? (
                                <div className="error-msg">{errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>{t("password")}</label><input type="password" className="form-control" 
                            name="password" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password} />
                            {errors.password ? (
                                <div className="error-msg">{errors.password}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>{t("password_confirmation")}</label><input type="password" className="form-control" 
                            name="password_confirmation" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password_confirmation} />
                            {errors.password_confirmation ? (
                                <div className="error-msg">{errors.password_confirmation}</div>
                            ) : null}
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                        <div className="form-group">
                            <img src="assets/images/nocaptcha-1.png" alt="" style={{margin:"10px 0px 0px"}} />
                        </div>
                    </div> */}
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label className="custom_checkbox"><input type="checkbox" name="check" onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.check} />
                            
                            <span className="chk_icon"></span>
                            <Trans i18nKey="agree_to_terms_and_condition">
                                I Agree to the <Link to={`/${lang}/terms-of-use`}>Terms and Conditions</Link>
                            </Trans>
                            </label>
                            {errors.check ? (
                                <div className="error-msg">{errors.check}</div>
                            ) : null}
                        </div> 
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="form-group">
                            {loadingRegister ? 
                            <button className="btn btn-rounded-yellow disable-link" disabled type="submit">{t("signup")}</button> :
                            <button className="btn btn-rounded-yellow" type="submit">{t("signup")}</button>}
                        </div>
                        <div className="form-group">
                            <p>{t("already_have_account")}</p>
                            <Link to={`/${lang}/login`}>{t("login")}</Link>
                        </div>
                    </div>
                </div>
            </form>
          );
        }}
        </Formik>
      );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        register: state.auth.register,
        loadingRegister: state.auth.loadingRegister,
        login_response: state.auth.login_response,
        loadingLogin: state.auth.loadingLogin,
        response: state.auth.response,
        error: state.auth.error,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        registerData: payload => dispatch(register.request(payload)),
        responseDismiss: () => dispatch(set_auth_state({ register: null })),
        loginData: payload => dispatch(login.request(payload)),
        responseLoginDismiss: () => dispatch(set_auth_state({ login_response: null })),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

export default enhance(RegisterForm);