import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import './Banner.css'
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";

class Banner3 extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            page : ""
         }
    }
    componentDidMount(){
        let url = window.location.href;
        let loginUrl = url.indexOf("login");
        let registerUrl = url.indexOf("register");
        let resetPasswordUrl = url.indexOf("reset-password");
        let whoAreWeUrl = url.indexOf("who-are-we");
        let termsOfUseUrl = url.indexOf("terms-of-use");
        let privacyPolicyUrl = url.indexOf("privacy-policy");
        let termsAndConditionUrl = url.indexOf("terms-and-condition");
        let contactusUrl = url.indexOf("contact-us");
        
        if(loginUrl >= 0){
            this.setState({
                page : "login"
            })
        }
        else if(registerUrl >= 0){
            this.setState({
                page : "register"
            })
        }else if(resetPasswordUrl >= 0){
            this.setState({
                page : "reset-password"
            })
        }else if(whoAreWeUrl >= 0){
            this.setState({
                page : "who-are-we"
            })
        }else if(termsOfUseUrl >= 0){
            this.setState({
                page : "termsOfUseUrl"
            })
        }else if(privacyPolicyUrl >= 0){
            this.setState({
                page : "privacyPolicyUrl"
            })
        }else if(termsAndConditionUrl >= 0){
            this.setState({
                page : "termsAndConditionUrl"
            })
        }else if(contactusUrl >= 0){
            this.setState({
                page : "contactusUrl"
            })
        }else{
            this.setState({
                page : "",
                banner_class : "inner_page_banner"
            })
        } 
        
    }
    render() { 
        const { t , customerDetail, lang} = this.props;
        return ( 
            <section className="inner_page_banner" >
                <div className="banner-overlay-image"><img  src={window.location.origin+"/assets/images/banner1.png"} /></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.page == "" &&
                            <h3 className="banner_heading">
                                {customerDetail ? customerDetail.responseData.first_name : ''}{" "} 
                                {customerDetail ? customerDetail.responseData.last_name : ''}
                            </h3>}
                            {this.state.page == "login" && 
                            <>
                            <h3 className="banner_heading">{t("login")}</h3>
                            <div className="breadcrumb_area">
                                <ul>
                                    <li><Link to={`/${lang}/bakeryList`}>{t("bakery")}</Link></li>
                                    <li>{t("login")}</li>
                                </ul>
                            </div>
                            </>}
                            {this.state.page == "register" && 
                            <>
                            <h3 className="banner_heading">{t("signup")}</h3>
                            <div className="breadcrumb_area">
                                <ul>
                                    <li><Link to={`/${lang}/bakeryList`}>{t("bakery")}</Link></li>
                                    <li>{t("signup")}</li>
                                </ul>
                            </div>
                            </>}
                            {this.state.page == "reset-password" && 
                            <>
                            <h3 className="banner_heading">{t("reset_password")}</h3>
                            <div className="breadcrumb_area">
                                <ul>
                                    <li><Link to={`/${lang}/bakeryList`}>{t("bakery")}</Link></li>
                                    <li>{t("reset_password")}</li>
                                </ul>
                            </div>
                            </>}
                            {this.state.page == "who-are-we" && 
                            <>
                            <h3 className="banner_heading">{t("who_are_we")}</h3>
                            <div className="breadcrumb_area">
                                <ul>
                                    <li><Link to={`/${lang}/bakeryList`}>{t("bakery")}</Link></li>
                                    <li>{t("who_are_we")}</li>
                                </ul>
                            </div>
                            </>}
                            {this.state.page == "termsOfUseUrl" && 
                            <>
                            <h3 className="banner_heading">{t("terms_of_use")}</h3>
                            <div className="breadcrumb_area">
                                <ul>
                                    <li><Link to={`/${lang}/bakeryList`}>{t("bakery")}</Link></li>
                                    <li>{t("terms_of_use")}</li>
                                </ul>
                            </div>
                            </>}
                            {this.state.page == "privacyPolicyUrl" && 
                            <>
                            <h3 className="banner_heading">{t("privacy_policy")}</h3>
                            <div className="breadcrumb_area">
                                <ul>
                                    <li><Link to={`/${lang}/bakeryList`}>{t("bakery")}</Link></li>
                                    <li>{t("privacy_policy")}</li>
                                </ul>
                            </div>
                            </>}
                            {this.state.page == "termsAndConditionUrl" && 
                            <>
                            <h3 className="banner_heading">{t("terms_and_condition")}</h3>
                            <div className="breadcrumb_area">
                                <ul>
                                    <li><Link to={`/${lang}/bakeryList`}>{t("bakery")}</Link></li>
                                    <li>{t("terms_and_condition")}</li>
                                </ul>
                            </div>
                            </>}
                            {this.state.page == "contactusUrl" && 
                            <>
                            <h3 className="banner_heading">{t("contact")}</h3>
                            <div className="breadcrumb_area">
                                <ul>
                                    <li><Link to={`/${lang}/bakeryList`}>{t("bakery")}</Link></li>
                                    <li>{t("contact")}</li>
                                </ul>
                            </div>
                            </>}
                        </div>
                    </div>
                </div>
            </section>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
    }
}

const enhance = compose(
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(Banner3);