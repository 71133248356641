import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import {Link} from 'react-router-dom'
import {
    get_top_bakery_list_data
} from "../../redux/reducers/bakery";
import { Trans } from 'react-i18next';
import "./PopularBakery.css"
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner  from "../Banner/Banner"

class PopularBakery extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data : [],
            lang : props.lang
         }
    }
    componentDidMount(){
        const { getBakeryData } = this.props;
        getBakeryData();

    }
    render() { 
        const { t, bakery_data, lang, getBakeryData } = this.props;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                getBakeryData();
            })
        }
        return ( 
            <>
            <Header />
            <Banner />
                <section className="popularies_wrap">
                    <div className="container">
                        <h2>
                            <Trans i18nKey="top_bakery_list">
                                Top Et Populaires <span>Boulangerie</span>
                            </Trans>
                        </h2>
                        <p>{t("top_bakery_list_tagline")}</p>
                        <div className="row">
                            {bakery_data && bakery_data.responseStatus.STATUS == "SUCCESS" && bakery_data.responseData.map((val) => (
                                
                                <div className="col-md-4" key={val.id}>
                                    <div className="popu_box">
                                        <Link to={`/${lang}/bakeryProfile/${val.id}`}>
                                            <div className="popu_box_imgcontent homepop-bakery">
                                               <img src={val.banner_image} alt="" />
                                               
                                            </div>
                                            <h4>{val.name}</h4>
                                            <p className="elips-para">{val.address}</p>
                                            
                                            <div className="popu_boxlink"><span>{t("open_now")}</span></div>
                                        </Link>
                                    </div>
                                </div>
                            ))}

                            {bakery_data && bakery_data.responseStatus.STATUS == "FAILED" &&
                                <div className="col-md-12 no_bakery" ><h4>{t("no_bakery_found")}</h4></div>
                            }
            
            
                        </div>
                    </div>
                </section>
            <Footer />
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        bakery_data: state.bakery.bakery_data,
        loading: state.bakery.loading,
        response: state.bakery.response,
        error: state.bakery.error,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getBakeryData: payload => dispatch(get_top_bakery_list_data.request(payload)),
    }
  }

const enhance = compose(
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(PopularBakery);