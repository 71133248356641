import React, { Component } from 'react';
import { withNamespaces, Trans } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import Slider from "react-slick";
import {
    get_search_bakery_list_data, post_customer_search_data
} from "../../redux/reducers/bakery";

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            search : "",
            lang : props.lang
         }
        this.handleSearch = this.handleSearch.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }
    componentDidMount(){
        let search = this.props.match.params.search;
        if(search){
            this.setState({
                search: search
            })
            this.props.getBakerySearchData({search: search})
            this.props.postSearchData({address : search})
        }else{
            this.setState({
                search: ''
            })
            this.props.getBakerySearchData({})
        }
    }
    handleSearch(event) {
        const target = event.target
        this.setState({
            search: target.value
        })
    }
    submitHandler(event) {
        const {lang } = this.props;
        event.preventDefault()
        if(this.state.search){
            this.props.history.push(`/${lang}/bakeryList/${this.state.search}`);
        }else{
            this.props.history.push(`/${lang}/bakeryList/`);
        }
        
    }
    componentWillReceiveProps(){
        let url = window.location.href;
        let search = url.split('bakeryList/')[1];
        if(search){
            search = decodeURIComponent(search)
            this.setState({
                search: search
            })
            this.props.getBakerySearchData({search: this.state.search})
            this.props.postSearchData({address : search})
        }else{
            this.setState({
                search: ''
            })
            this.props.getBakerySearchData({})
        }
    }
    render() { 
        const { t, history, lang, getBakerySearchData } = this.props;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                getBakerySearchData({search: this.state.search})
            })
        }
        const settings = {
            vertical: true,
            autoplay: true,
            autoplaySpeed: 3000,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return ( 
            <section>
                <div className="banerwrap">
                <div className="banerwrap_bgoverlay">
                    <div className="banerheading">
                        <h1>
                        <Trans i18nKey="heading">
                            The <span>Boulangerie</span>
                        </Trans>
                        </h1>
                    </div>
                    <div className="vertical slider">
                    <Slider {...settings}>
                        <h3>{t("banner_tagline1")}</h3>
                        <h3>{t("banner_tagline2")}</h3>
                        <h3>{t("banner_tagline3")}</h3>
                        <h3>{t("banner_tagline4")}</h3>
                    </Slider>
                    </div>
                </div>
                    <div className="formwrapper">
                        <form onSubmit={this.submitHandler}>
                        <div className="row">
                            <div className=" col-8 col-md-9">
        
                                <div className="form-box">
                                    {/* <i className="fa fa-map-marker" aria-hidden="true"></i> */}
        
                                    <input type="text" name="" value={this.state.search} placeholder={t('search')}  className="form-boxtextfeild" onChange={this.handleSearch} />
                                </div>
        
                            </div>
                            <div className="col-4 col-md-3">
                                <input name="" type="submit" value={t('ok')} className="submit_btn" />
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </section>
         );
    }
}
const mapStateToProps = state => {
    return {
        lang: state.setting.lng
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getBakerySearchData: payload => dispatch(get_search_bakery_list_data.request(payload)),
        postSearchData: payload => dispatch(post_customer_search_data.request(payload)),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

export default enhance(Banner);