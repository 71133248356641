import React, { Component } from 'react';
import { withNamespaces ,Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner3  from "../Banner/Banner3"

class TermsAndCondition extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() { 
        const { t,lang } = this.props;
        return ( 
            <>
            <Header />
            <Banner3 />
            <div className="container">
            <div className="ab_wrapshadow">
            <div className="row">
                <div className="col-lg-12" >
                    <>
                        <p><b>{t("termsAndCond1")}</b>
                        {t("termsAndCond2")}<br/>
                        <b>{t("termsAndCond3")}</b>{t("termsAndCond4")} <a href={"maito:"+t("termsAndCond5")}>{t("termsAndCond5")}</a>
                        </p>
                        <h3>{t("termsAndCond6")}</h3>
                        <ol>
                            <li>{t("termsAndCond7")} <a href={t("termsAndCond8")}>{t("termsAndCond8")}</a> 
                            {t("termsAndCond9")}
                            <ul>
                                <li>{t("termsAndCond10")}</li>
                                <li>{t("termsAndCond11")}</li>
                            </ul>
                            </li>
                            <li>{t("termsAndCond12")}</li>
                            <li>{t("termsAndCond13")}</li>
                            <li>{t("termsAndCond14")}</li>
                        </ol>
                        <h3>{t("termsAndCond15")}</h3>
                        <ol>
                            <li>{t("termsAndCond16")}</li>
                            <li>{t("termsAndCond17")}</li>
                            <li>{t("termsAndCond18")}</li>
                        </ol>
                        <h3>{t("termsAndCond19")}</h3>
                        <ol style={{padding:"0 0 0 0"}}>
                            <li>{t("termsAndCond20")}</li>
                            <li>{t("termsAndCond21")}</li>
                            <li>{t("termsAndCond22")}</li>
                            <li>{t("termsAndCond23")}</li>
                            <li>{t("termsAndCond24")}
                            <ul >
                                <li>{t("termsAndCond25")}</li>
                                <li>{t("termsAndCond26")}</li>
                                <li>{t("termsAndCond27")}</li>
                                <li>{t("termsAndCond28")}</li>
                            </ul>
                            </li>
                        </ol>
                        <h3>{t("termsAndCond29")}</h3>
                        <p style={{paddingBottom:"0"}}>{t("termsAndCond30")}</p>
                        <ol>
                            <li>{t("termsAndCond31")}</li>
                        </ol>
                        <h3>{t("termsAndCond32")}</h3>
                        <ol>
                            <li>{t("termsAndCond33")}</li>
                            <li>{t("termsAndCond34")}</li>
                        </ol>
                        <h3>{t("termsAndCond35")}</h3>
                        <ol>
                            <li>{t("termsAndCond36")}</li>
                            <li>{t("termsAndCond37")}</li>
                            <li>{t("termsAndCond38")}</li>
                        </ol>
                        <h3>{t("termsAndCond39")}</h3>
                        <ol>
                            <li>{t("termsAndCond40")}</li>
                            <li>{t("termsAndCond41")}</li>
                            <li>{t("termsAndCond42")}</li>
                            <li>{t("termsAndCond43")}</li>
                            <li>{t("termsAndCond44")} <a href={"maito:"+t("termsAndCond66")}>{t("termsAndCond66")}</a>
                            {t("termsAndCond45")}</li>
                        </ol>
                        <h3>{t("termsAndCond46")}</h3>
                        <ol>
                            <li>{t("termsAndCond47")}</li>
                            <li>{t("termsAndCond48")}</li>
                        </ol> 
                        <h3>{t("termsAndCond49")}</h3>
                        <ol style={{padding:"0 0 0 0"}}>
                            <li>{t("termsAndCond50")}<br/>
                            {t("termsAndCond51")}<br/>
                            {t("termsAndCond52")}
                            <ul>
                                <li>{t("termsAndCond53")}</li>
                                <li>{t("termsAndCond54")}</li>
                            </ul>
                            </li>
                        </ol>    
                        <h3>{t("termsAndCond55")}</h3>   
                        <ol>
                            <li>{t("termsAndCond56")}</li>
                            <li>{t("termsAndCond57")}</li>
                        </ol> 
                        <h3>{t("termsAndCond58")}</h3>                    
                        <ol>
                            <li>{t("termsAndCond59")}</li>
                            <li>{t("termsAndCond60")}</li>
                            <li>{t("termsAndCond61")}</li>
                            <li>{t("termsAndCond62")}</li>
                        </ol> 
                        <h3>{t("termsAndCond63")}</h3>
                        <ol>
                            <li>{t("termsAndCond64")}</li>
                            <li>{t("termsAndCond65")}</li>
                        </ol>
                    </>
                    
                </div>
            </div>
            </div>
            </div>
            <Footer />
            
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    }
  }

const enhance = compose(
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
export default enhance(TermsAndCondition);