import isObject from "lodash/isObject";

export function formatError(err, defError = ["Someting went wrong"]) {
  const parsedErr = JSON.parse(JSON.stringify(err));

  if (parsedErr.hasOwnProperty("response")) {
    const { data, statusText } = parsedErr.response;
    let errorData;
    if (isObject(data)) {
      errorData = Object.values(data).map(item => (Array.isArray(item) ? item.join("\n") : item));
    } else {
      errorData = [data];
    }

    return { data: errorData, statusText: statusText };
  } else {
    return { data: defError, statusText: "Error" };
  }
}

export function cleanPayload(payload) {
  let params = {};
  for (const key in payload) {
    if (payload[key]) {
      params[key] = payload[key];
    } else {
      params[key] = null;
    }
  }
  return params;
}
