import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Slider from "react-slick";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  Banner2  from "../Banner/Banner2"
import {
    get_product_list_data
} from "../../redux/reducers/product";
import {
    set_bakery_state
} from "../../redux/reducers/bakery";
import {
    add_to_cart, set_order_state, cart_list
} from "../../redux/reducers/order";
import { CheckForSession, showNotification, getSessionOrAuthorizedToken } from '../../Helper/Helper'
import './ProductList.css'

class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang : props.lang,
            bakery_id : ''
         };
        this.addToCart = this.addToCart.bind(this)
    }
    componentDidMount(){
        const {  getProductData, match, responseDismiss , cartList, loginDetails} = this.props;
        let id = match.params.id;
        if(id){
            this.setState({
                bakery_id : id
            })
            getProductData({encryptId:id})
            responseDismiss();
            let data = getSessionOrAuthorizedToken(loginDetails);
            cartList(data)
        }
    }
    addToCart(product_id, price ,quantity){
        const { t, addToCart } = this.props;
        let session = CheckForSession();
        let data = {
            session_id : session,
            bakery_id : this.state.bakery_id,
            product_id : product_id,
            price : price,
            quantity : quantity
        }
        addToCart(data)
        
    }
    render() { 
        const { t , product_list_data, lang,  getProductData, match, add_to_cart , loadingOrder, responseDismiss,
            cartList, cart_list_data, loginDetails, bakery_profile_data, responseAdd } = this.props;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                let id = match.params.id;
                if(id){
                    getProductData({encryptId:id})
                }
            })
        }
        const settings = {
            dots: false, infinite: false, speed: 1000, slidesToShow: 4, slidesToScroll: 1, centerMode: false, focusOnSelect: false,
            responsive: [
                {
                    breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1, infinite: true, }
                },
                {
                    breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 1 }
                },
                {
                    breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 }
                }
            ]
          };
          
          if(add_to_cart && add_to_cart.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),t("added_to_cart"),'success');
            responseDismiss();
            let data = getSessionOrAuthorizedToken(loginDetails);
            cartList(data)
          }
            
          if(add_to_cart && add_to_cart.responseStatus.STATUS == "FAILED"){
            showNotification(t("error"),add_to_cart.responseStatus.MESSAGE,'danger');
            responseDismiss();
          }

          if(!bakery_profile_data){
              if(product_list_data && product_list_data.responseStatus.STATUS == "SUCCESS"){
                responseAdd({bakery_profile_data:product_list_data.responseData.bakeryProfile})
              }
            
          }
            let data = '';
            if(product_list_data && product_list_data.responseStatus.STATUS == "SUCCESS"){
                data = product_list_data.responseData;
                delete data['bakeryProfile']; 
            }
        return ( 
            <>
            <Header />
            <Banner2 />
            <div className="all_product_slider_section gray-bg">
            {data && Object.keys(data).map((category) => (

                
                <div className="product_slider_area" key={category}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h5>{category}</h5>
                                <div className="product_slider_wrapper">
                                    <div className="product_list_slider">
                                    <Slider {...settings}>
                                        {data[category].map((val)=>(

                                        
                                        <div key={val.id}>
                                            
                                            <div className="product_box">
                                                <Link to={`/${lang}/productDetails/${val.id}`}>
                                                    <div className="popu_box_imgcontent height-auto">
                                                        <img src={val.image} alt="" />
                                                    </div>
                                                    <div className="product_info_area">
                                                        <h4>{val.name}</h4>
                                                        
                                                    </div>
                                                </Link>
                                                <div className="product_price_area"><span> {val.price} €</span></div>
                                                <div className="action_area">
                                                    
                                                    
                                                    
                                                    {cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS" 
                                                    && cart_list_data.responseData.orderDetails.find(({product_id}) => product_id == val.id) ?
                                                    <Link to={`/${lang}/cart`} className="btn btn-block btn-rounded-yellow" >
                                                    {t("go_to_cart")}
                                                    </Link> :                                                     
                                                    loadingOrder ? 
                                                    <a className="btn btn-block btn-rounded-yellow disable-link" >
                                                        {t("add_to_cart")}
                                                    </a> : 
                                                    <a className="btn btn-block btn-rounded-yellow" 
                                                    onClick={() => this.addToCart(val.id,val.price,1)}>
                                                        {t("add_to_cart")}
                                                    </a>
                                                    }
                                                </div>
                                            </div>
                                            
                                        </div>
                                        ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                ))}
                {product_list_data && product_list_data.responseStatus.STATUS == "FAILED" &&  
                    <div className="col-md-12 no_bakery" ><h4 style={{textAlign: "center"}}>{t("no_product_found")}</h4></div>
                }
            </div>
            <Footer />
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        product_list_data: state.product.product_list_data,
        loading: state.product.loading,
        response: state.product.response,
        error: state.product.error,
        add_to_cart: state.order.add_to_cart,
        loadingOrder: state.order.loading,
        cart_list_data: state.order.cart_list_data,
        loginDetails: state.auth.login_details,
        bakery_profile_data: state.bakery.bakery_profile_data,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getProductData: payload => dispatch(get_product_list_data.request(payload)),
        addToCart: payload => dispatch(add_to_cart.request(payload)),
        cartList: payload => dispatch(cart_list.request(payload)),
        responseDismiss: () => dispatch(set_order_state({ add_to_cart: null })),
        responseAdd: payload => dispatch(set_bakery_state(payload)),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
 
export default enhance(ProductList);